const
    SET_VIEWABLE_RELEVANT_VACANCY = 'setViewableRelevantVacancy',
    SET_RELEVANT_VACANCIES = 'setRelevantVacancies',
    REMOVE_RELEVANT_VACANCY_FROM_LIST = 'removeRelevantVacancyFromList',
    SET_ARTISTIC_PROFILE_RELEVANT_VACANCIES_PAGINATION = 'setArtisticProfileRelevantVacanciesPagination',
    SET_FILTER_TARGET_ARTISTIC_PROFILE_ID = 'setFilterTargetArtisticProfileId';

export {
    SET_VIEWABLE_RELEVANT_VACANCY,
    SET_RELEVANT_VACANCIES,
    REMOVE_RELEVANT_VACANCY_FROM_LIST,
    SET_ARTISTIC_PROFILE_RELEVANT_VACANCIES_PAGINATION,
    SET_FILTER_TARGET_ARTISTIC_PROFILE_ID,
};