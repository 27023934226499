import {
    ADD_ARTISTIC_APPLICATION,
    REMOVE_ARTISTIC_APPLICATION_FROM_LIST,
    SET_ARTISTIC_APPLICATIONS,
    SET_ORGANIZER_ARTISTIC_APPLICATIONS,
    UPDATE_ARTISTIC_APPLICATION_IN_LIST,
    UPDATE_ARTISTIC_APPLICATION_STATUS_IN_LIST,
    SET_FILTER_FOR_VACANCY,
    SET_FILTER_STATUS,
    REMOVE_ORGANIZER_ARTISTIC_APPLICATION_FROM_LIST,
    UPDATE_ORGANIZER_ARTISTIC_APPLICATION_STATUS_IN_LIST,
    TOGGLE_ORGANIZER_APPLICATION_PROFILE_IN_COLLECTION_STATUS, SET_ARTISTIC_APPLICATION_ATTRIBUTE,
} from './mutation-types';
import {SET_APPLICATION_CONVERSATION} from "@/store/modules/artisticApplications/actions-types";
import Vue from "vue";

export default {
    [SET_ARTISTIC_APPLICATIONS](state, applications) {
        state.applications = applications;
    },
    [SET_ORGANIZER_ARTISTIC_APPLICATIONS](state, organizerApplications){
        state.organizerApplications = organizerApplications;
    },
    [REMOVE_ARTISTIC_APPLICATION_FROM_LIST](state, index){
        state.applications.slice(index, 1);
    },

    [ADD_ARTISTIC_APPLICATION](state, application){
        state.applications.push(application);
    },
    [UPDATE_ARTISTIC_APPLICATION_IN_LIST](state, applicationToUpdate){
        let index = state.applications.findIndex(application => application.id === applicationToUpdate.id);
        state.applications.splice(index, 1, applicationToUpdate);
    },
    [UPDATE_ARTISTIC_APPLICATION_STATUS_IN_LIST](state, payload){
        let index = state.applications.findIndex(application => application.id === payload.applicationId);
        state.applications[index].status = payload.status;
    },
    [UPDATE_ORGANIZER_ARTISTIC_APPLICATION_STATUS_IN_LIST](state, payload){
        let index = state.organizerApplications.findIndex(application => application.id === payload.applicationId);
        state.organizerApplications[index].status = payload.status;
    },
    [SET_FILTER_FOR_VACANCY](state, vacancyId){
        state.filter.forVacancy = vacancyId;
    },
    [SET_FILTER_STATUS](state, status){
        state.filter.status = status;
    },
    [REMOVE_ORGANIZER_ARTISTIC_APPLICATION_FROM_LIST](state, applicationId){
        let index = state.organizerApplications.findIndex(application => application.id === applicationId);
        state.organizerApplications.splice(index, 1);
    },
    [TOGGLE_ORGANIZER_APPLICATION_PROFILE_IN_COLLECTION_STATUS](state, applicationId){
        let application = state.organizerApplications.find(application => application.id === applicationId);
        application.artistic_profile.in_favorite_collection = !application.artistic_profile.in_favorite_collection;
    },
    [SET_APPLICATION_CONVERSATION](state, {conversation, applicationId}){
        if (state.organizerApplications){
            let organizerApplication = state.organizerApplications.find(a => a.id === applicationId);
            if (organizerApplication){
                Vue.set(organizerApplication, 'conversation', conversation);
            }
        }
        if (state.applications){
            let application = state.applications.find(a => a.id === applicationId);
            if (application){
                Vue.set(application, 'conversation', conversation);
            }
        }
    },
    /**
     * @param {object} state
     * @param {number} applicationId
     * @param {string} key
     * @param  value
     * @param  {boolean} organizer
     */
    [SET_ARTISTIC_APPLICATION_ATTRIBUTE](state, {applicationId, key, value,organizer= false }){
        let stateKey = organizer ? 'organizerApplications' : 'applications';
        if (state[stateKey]){
            let applicationIndex = state[stateKey].findIndex(a => a.id === applicationId);
            if (applicationIndex >= 0){
                state[stateKey].splice(applicationIndex, 1, {...state[stateKey][applicationIndex], ...{[key]: value}});
            }
        }
    }
};
