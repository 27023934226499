import axios from '@/plugins/axios';
import {GET_RELEVANT_VACANCIES, GET_RELEVANT_VACANCY, GET_RELEVANT_VACANCIES_FOR_ARTISTIC_PROFILE} from './action-types';
import {
    SET_VIEWABLE_RELEVANT_VACANCY,
    SET_RELEVANT_VACANCIES,
    SET_ARTISTIC_PROFILE_RELEVANT_VACANCIES_PAGINATION
} from "./mutation-types";

export default {
    [GET_RELEVANT_VACANCIES]({commit}){
        return new Promise((resolve, reject) => {
            axios.get('relevant-vacancies')
                .then(res => {
                    commit(SET_RELEVANT_VACANCIES, res.data);
                    resolve(res);
                }).catch(errors => {
                reject(errors);
            });
        });
    },
    [GET_RELEVANT_VACANCY]({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('relevant-vacancies/' + payload.vacancyId, {params: payload.params} )
                .then(res => {
                    commit(SET_VIEWABLE_RELEVANT_VACANCY, {...res.data, ...{'target_artistic_profile_id': parseInt(payload.targetArtisticProfileId)}});
                    resolve(res);
                }).catch(errors => {
                reject(errors);
            });
        });
    },
    [GET_RELEVANT_VACANCIES_FOR_ARTISTIC_PROFILE]({commit}, {artisticProfileId, page = 1}){
        return new Promise((resolve, reject) => {
            axios.get(`relevant-vacancies/artistic-profile/${artisticProfileId}`, {params: {page}})
                .then(res => {
                    commit(SET_RELEVANT_VACANCIES, res.data.data);
                    commit(SET_ARTISTIC_PROFILE_RELEVANT_VACANCIES_PAGINATION, res.data.meta);
                    resolve(res);
                }).catch(errors => {
                reject(errors);
            });
        });
    },
};
