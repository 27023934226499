import {
    GET_APPLICATION_TYPES,
    GET_DATA_FOR_CREATION,
    SET_APPLICATION_LOCALE,
    SKIP_WORKER_WAITING
} from './action-types';
import axios from '@/plugins/axios';
import {SET_ARTISTIC_PROFILE_TYPES} from "../artisticProfileTypes/mutation-types";
import {SET_INSTRUMENTS} from "../instruments/mutation-types";
import {SET_STYLISTIC_OPTION_TYPES} from "../stylisticOptionTypes/mutation-types";
import {SET_CITIES} from "../cities/mutation-types";
import {SET_APPLICATION_TYPES, SET_UPDATES_AVAILABLE} from "@/store/modules/base/mutation-types";
import {SET_ARTISTIC_PROFILE_FEATURES} from "@/store/modules/artisticProfileFeatures/mutation-types";
/**
*
* @param { function } commit
* @param { string } data
*/
export default {
    async [GET_DATA_FOR_CREATION]({commit}) {
        return axios.get('data-for-vacancy-or-artistic-profile-creation')
            .then(res => {
                let { artistic_profile_types, instruments, stylistic_option_types, cities, artistic_profile_features} = res.data;
                commit('cities/' + SET_CITIES, cities, {root: true});
                commit('artisticProfileTypes/' + SET_ARTISTIC_PROFILE_TYPES, artistic_profile_types, {root: true});
                commit('instruments/' + SET_INSTRUMENTS, instruments, {root: true});
                commit('stylisticOptionTypes/' + SET_STYLISTIC_OPTION_TYPES, stylistic_option_types, {root: true});
                commit('artisticProfileFeatures/' + SET_ARTISTIC_PROFILE_FEATURES, artistic_profile_features, {root: true});
                return res;
            }).catch(errors => {
                return Promise.reject(errors);
            });
    },
    [SKIP_WORKER_WAITING]({commit, state}){
        commit(SET_UPDATES_AVAILABLE, false);
        state.workerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
    async [GET_APPLICATION_TYPES]({commit}){
        return axios.get('application-types')
            .then(res => {
                commit(SET_APPLICATION_TYPES, res.data);
                return res;
            }).catch(error => {
                return Promise.reject(error);
            });
    },
    async [SET_APPLICATION_LOCALE]({commit}, form){
        commit(SET_APPLICATION_LOCALE, form.locale);
    },
    async getCaptcha(){
        return axios.get('captcha/math');
    }
};
