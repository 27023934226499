import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        //meta: {auth: false},
    },
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../views/Auth/Register.vue'),
        meta: {requireGuest: true},
    },
    {
        path: '/authentication',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue'),
        meta: {requireGuest: true},
        props: true,
    },
    {
        path: '/password-reset',
        name: 'passwordReset',
        component: () => import(/* webpackChunkName: "passwordReset" */ '../views/Auth/PasswordReset.vue'),
        params: true,
        meta: {requireGuest: true},
    },
    {
        path: '/email/verify',
        name: 'emailVerify',
        component: () => import(/* webpackChunkName: "emailVerify" */ '../views/Auth/EmailVerify.vue'),
        meta: {requireAuth: true},
        params: true,
    },
    {
        path: '/reports',
        name: 'reports',
        component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
    },
    {
        path: '/profile',
        name: 'userProfile',
        component: () => import(/* webpackChunkName: "userProfile" */ '../views/UserProfile'),
        meta: {requireAuth: true},
    },
    {
        path: '/subscription',
        name: 'subscription',
        component: () => import(/* webpackChunkName: "subscriptionPage" */ '../views/Subscription'),
        meta: {requireAuth: true}
    },
    {
        path: '/subscription/plan/:plan_id',
        name: 'plan',
        component: () => import(/* webpackChunkName: "planPage" */ '../views/Plan'),
        meta: {requireAuth: true},
    },
    {
        path: '/subscription/checkout/:invoice_id',
        name: 'checkout',
        component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout'),
        meta: {requireAuth: true},
    },
    {
        path: '/artist',
        component: {
            render: h => h('router-view')
        },
        meta: {requireAuth: true},
        children: [
            {
                path: '',
                name: 'artisticDashboard',
                component: () => import(/* webpackChunkName: "artisticDashboard" */ '../views/Artist/ArtisticDashboard.vue'),
                meta: {requireAuth: true}
            },
            {
                path: 'vacancy/:relevant_vacancy_id',
                name: 'relevantVacancyView',
                component: () => import(/*  webpackChunkName: "relevantVacancyView" */ '../views/Artist/RelevantVacancyView'),
                meta: {requireAuth: true},
            },
            {
                path: 'projects',
                name: 'artisticProfiles',
                component: () => import(/* webpackChunkName: "artisticProfiles" */ '../views/Artist/ArtisticProfiles.vue'),
                meta: {requireAuth: true},
            },
            {
                path: 'projects/edit/:id',
                name: 'editArtisticProfile',
                component: () => import(/*webpackChunkName: "editArtisticProfile"*/ '../views/Artist/EditArtisticProfile.vue'),
                meta: {requireAuth: true},
            }
        ],
    },
    {
        path: '/organizer',
        component: {
            render: h => h('router-view'),
        },
        children: [
            {
                path: '',
                name: 'organizerDashboard',
                component: () => import(/* webpackChunkName: "organizerDashboard" */ '../views/Organizer/OrganizerDashboard.vue'),
                meta: {requireAuth: true},
            },
            {
                path: 'vacancies',
                name: 'organizerVacancies',
                component: () => import(/* webpackChunkName: "organizerVacancies" */ '../views/Organizer/OrganizerVacancies.vue'),
                meta: {requireAuth: true, requireOrganizer: true},
            },
            {
                path: 'vacancies/edit/:id',
                name: 'editVacancy',
                component: () => import(/* webpackChunkName: "editVacancy" */ '../views/Organizer/EditOrganizerVacancy.vue'),
                meta: {requireAuth: true, requireOrganizer: true},
            },
            {
                path: 'collection/profiles',
                name: 'organizerArtisticProfilesCollection',
                component: () => import(/* webpackChunkName: "organizerArtisticProfilesCollection" */ '@/views/Organizer/ArtisticProfilesCollection'),
                meta: {requireAuth: true, requireOrganizer: true},
            }
        ],
    },
    {
        path: '*',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    }

];
/*
* Create Router instance
* */
const router = new VueRouter({
    mode: 'history',
    routes
});

/*
* Define navigation guards
* */

router.beforeEach(async (to, from, next) => {

    const isAuthenticated = store.getters["auth/isAuthenticated"];
    const emailVerified = store.getters["auth/isVerified"];

    /*AUTH REQUIRED*/
    if (to.matched.some(record => record.meta.requireAuth)) {

        if (!isAuthenticated) {
            next({name: 'login'});

        } else if (!emailVerified && to.name !== 'emailVerify') {

            next({name: 'emailVerify'});

        } else {
            /*ORGANIZER REQUIRED*/
            if (to.matched.some(record => record.meta.requireOrganizer)) {
                const isOrganizer = store.getters["auth/isOrganizer"];
                if (isOrganizer) {

                    next();

                } else {
                    next({name: 'organizerDashboard'});
                }
            }
            next();
        }

    /*GUEST REQUIRED*/
    } else if (to.matched.some(record => record.meta.requireGuest)) {

        if (isAuthenticated) {

            next({name: 'home'});

        } else {

            next();

        }

    } else {
        next();
    }
});


export default router;
