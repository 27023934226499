 import {
    LOGOUT,
    LOGIN,
    AUTH,
    REGISTER,
    VERIFY_EMAIL,
    RESEND_VERIFY_EMAIL,
    SEND_RESET_PASSWORD_LINK, RESET_PASSWORD
} from './action-types';
import axios from '@/plugins/axios';
import {UPDATE_USER} from "@/store/modules/auth/action-types";
import {SET_USER_ATTRIBUTE} from "@/store/modules/auth/mutation-types";

/**
 *
 * @param { function } commit
 * @param { string } data
 */
export default {
    async [LOGIN]({dispatch}, payload){
        await axios({url: 'sanctum/csrf-cookie', baseURL: process.env.VUE_APP_BACKEND_URL});
        let res = await axios.post('/login', payload);
        await dispatch(AUTH);
        return res;
    },
    async [LOGOUT]({dispatch}){
        await axios.post('logout');

        await dispatch(AUTH);
    },
    async [REGISTER]({dispatch}, credentials){
        return axios.post('register', credentials)
            .then( async res => {
                await dispatch(AUTH);
                return res;
            }).catch(errors => {
                return Promise.reject(errors);
            });
    },
    async [AUTH]({commit}){
        return axios.get('auth').then(res => {
            if (res.data.user){
                commit('setUser', res.data.user);
            } else {
                commit('setUser', null);
            }
            return res;
        }).catch(errors => {
            commit('setUser', null);
            //we should return errors rather then throw error, see main.js vue app initialization
            return errors;
        });
    },
    async [UPDATE_USER]({commit}, payload){
        return axios.put('user-profiles', payload)
            .then(res => {
                commit('setUser', res.data);
                return res;
            }).catch(errors => {
                throw new Error(errors);
            });
    },
    async [VERIFY_EMAIL]({commit}, url){
        return axios.get(url)
            .then(res => {
                commit(SET_USER_ATTRIBUTE, {
                    attributeName: 'email_verified_at',
                    attributeValue: res.data.email_verified_at,
                });
                return res;
            }).catch(errors => {
                throw new Error(errors);
            });
    },
    async [RESEND_VERIFY_EMAIL]({commit}){
        commit;
        return axios.post('email/verify')
            .then(res => {
                return res;
            });
    },
    async [SEND_RESET_PASSWORD_LINK]({commit}, email){
        commit;
        return axios.post('forgot-password', {email})
            .then(res => {
                return res;
            })
            .catch(errors => {
                return Promise.reject(errors);
            });
    },
    async [RESET_PASSWORD]({commit}, payload){
        commit;
        return axios.post( `reset-password`, {
            email: payload.email,
            token: payload.token,
            password: payload.password,
            password_confirmation: payload.password_confirmation,
        }).then(res => {
            return res;
            })
            .catch((errors) => {
                return Promise.reject(errors);
            });
    }
};
