import { SET_ARTISTIC_PROFILE_FEATURES } from './mutation-types';

export default {
    /**
    *
    * @param { object } state
    * @param { string } data
    */
    [SET_ARTISTIC_PROFILE_FEATURES](state, features) {
        state.features = features;
    },
};
