<template>
    <section class="container">
        <b-tabs position="is-centered is-toggle" type="is-primary" size="is-medium" class="block" >
            <b-tab-item :label="$t('Registration')">
                <register-form />
            </b-tab-item>
            <b-tab-item :label="$t('Login')">
                <login-form/>
            </b-tab-item>
        </b-tabs>
    </section>
</template>

<script>
    import RegisterForm from "./RegisterForm";
    import LoginForm from "@/components/Auth/LoginForm";
    export default {
        name: "AuthForm",
        components: {LoginForm, RegisterForm}
    };
</script>

<style scoped>

</style>