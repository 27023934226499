import { SET_CITIES } from './mutation-types';

export default {
    /**
    *
    * @param { object } state
    * @param { string } data
    */
    [SET_CITIES](state, cities) {
        state.cities = cities;
    },
};
