const
    SET_UPDATES_AVAILABLE = 'setUpdatesAvailable',
    SET_WORKER_REGISTRATION = 'setWorkerRegistration',
    SET_APPLICATION_TYPES = 'setApplicationTypes',
    SET_APPLICATION_LOCALE = 'setApplicationLocale';

export {
    SET_UPDATES_AVAILABLE,
    SET_WORKER_REGISTRATION,
    SET_APPLICATION_TYPES,
    SET_APPLICATION_LOCALE,
};