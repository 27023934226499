<template>

    <div class="container">
        <div class="columns is-centered">

            <div class="column is-half-fullhd">
                <div class="box">
                    <form @submit.prevent="handleRegister">
                        <h4 class="title is-4" data-cy="register-form-title">{{$t('Registration')}}</h4>

                        <b-field :label="$t('Name')"
                                 :message="$_p_formGetError('name')"
                                 :type="{'is-danger': $_p_formHasError('name')}">
                            <b-input v-model="credentials.name"
                                     data-cy="user_name"
                                     name="first_name"
                                     :placeholder="$t('Name')"
                                     icon="user"
                                     @input="$_p_formClearError('name')">
                            </b-input>
                        </b-field>

                        <b-field :label="$t('Email')"
                                 :message="$_p_formGetError('email')"
                                 :type="{'is-danger': $_p_formHasError('email')}">
                            <b-input v-model="credentials.email"
                                     data-cy="email"
                                     @input="$_p_formClearError('email')"
                                     type="email"
                                     name="email"
                                     icon="envelope"
                                     :placeholder="emailPlaceholder">
                            </b-input>
                        </b-field>

                        <b-field :label="$t('Password')"
                                 :message="$_p_formGetError('password')"
                                 :type="{'is-danger': $_p_formHasError('password')}">
                            <b-input type="password"
                                     data-cy="password"
                                     @input="$_p_formClearError('password')"
                                     name="password"
                                     placeholder="********"
                                     v-model="credentials.password"
                                     icon="lock">
                            </b-input>
                        </b-field>

                        <b-field :label="$t('Confirm password')"
                                 :message="$_p_formGetError('password')"
                                 :type="{'is-danger': $_p_formHasError('password')}">
                            <b-input type="password"
                                     @keyup.native.enter="handleRegister"
                                     data-cy="password_confirmation"
                                     name="password_confirmation"
                                     @input="$_p_formClearError('password')"
                                     placeholder="********"
                                     v-model="credentials.password_confirmation"
                                     icon="lock">
                            </b-input>
                        </b-field>

                        <b-field>
                            <b-switch data-cy="organize_profile" v-model="credentials.organizer_profile">{{$t('I\'m organizer')}}</b-switch>
                        </b-field>

                        <template v-if="credentials.organizer_profile">
                            <cities-list :has-error="$_p_formHasError('city')"
                                         :errors="errors"
                                         :error-message="$_p_formGetError('city')"
                                         @selected="city => credentials.city_id = city.id"
                                         @clear-error="$_p_formClearError('city_id')" />

                            <b-field :label="$t('Workplace')"
                                     :type="{'is-danger': $_p_formHasError('work_place')}"
                                     :message="$_p_formGetError('work_place')">
                                <b-input icon="home"
                                         v-model="credentials.work_place"
                                         @input="$_p_formClearError('work_place')"> </b-input>
                            </b-field>

                            <b-field :label="$t('Position')"
                                     :type="{'is-danger': $_p_formHasError('position')}"
                                     :message="$_p_formGetError('position')">
                                <b-input icon="briefcase"
                                         v-model="credentials.position"
                                         @input="$_p_formClearError('position')"> </b-input>
                            </b-field>
                        </template>

                        <b-field class="has-text-right">
                            <b-button data-cy="submit_registration"
                                      type="is-primary"
                                      class="register"
                                      @click="handleRegister"
                                      :loading="formBusy">{{$t('Registration')}}
                            </b-button>
                        </b-field>

                    </form>


                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import getRandomEmailPlaceholderMixin from "@/mixins/getRandomEmailPlaceholderMixin";
    import {REGISTER} from "@/store/modules/auth/action-types";
    import {mapActions} from "vuex";
    import formHelperMixin from "@/mixins/formHelperMixin";
    import CitiesList from "@/components/Forms/parts/common/CitiesList";
    export default {
        components: {CitiesList},
        data: function(){
            return {
                credentials: {
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    organizer_profile: false,
                    city_id: null,
                    work_place: null,
                    position: null,
                },
                emailPlaceholder: null,
            };
        },
        methods: {
            ...mapActions('auth', [
                REGISTER,
            ]),
            handleRegister(){

                this.$_p_formDispatch(REGISTER, this.credentials)
                    .then(() => {
                        this.$gEvent(
                            'registered',
                            {user_email: this.credentials.email, is_organizer: this.credentials.organizer_profile}
                        );
                        this.$router.push({name: 'emailVerify'});
                    }).catch(error => {
                        this.$gEvent('registration_attempt', {error});
                    });

            },

        },
        created() {
            this.emailPlaceholder = this.$_p_getRandomEmailPlaceholder();
        },
        mixins: [getRandomEmailPlaceholderMixin, formHelperMixin],
        name: "RegisterForm",
    };
</script>

<style scoped>

</style>