import { GET_STYLISTIC_OPTION_TYPES } from './action-types';
import axios from "@/plugins/axios";
import {SET_STYLISTIC_OPTION_TYPES} from "./mutation-types";

/**
*
* @param { function } commit
* @param { string } data
*/
export default {
    async [GET_STYLISTIC_OPTION_TYPES]({commit}) {
        return axios.get('data-for-vacancy-or-artistic-profile-creation')
            .then(res => {
                let { stylistic_option_types  } = res.data;
                commit(SET_STYLISTIC_OPTION_TYPES, stylistic_option_types);
                return res;
            }).catch(errors => {
                return errors;
            });
    }
};
