import { SET_STYLISTIC_OPTION_TYPES } from './mutation-types';

export default {
    /**
     *
     * @param { object } state
     * @param stylisticOptionTypes
     */
    [SET_STYLISTIC_OPTION_TYPES](state, stylisticOptionTypes) {
        state.types = stylisticOptionTypes;
    },
};
