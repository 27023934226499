const  SET_ARTISTIC_VACANCIES  = 'setArtisticVacancies',
    SET_ARTISTIC_VACANCIES_COUNT = 'setArtisticVacanciesCount',
    ADD_ARTISTIC_VACANCY = 'addArtisticVacancy',
    SET_EDITED_ARTISTIC_VACANCY = 'setEditedArtisticVacancy',
    UPDATE_ARTISTIC_VACANCY_IN_LIST = 'updateArtisticVacancyInList',
    DELETE_ARTISTIC_VACANCY_FROM_LIST = 'deleteArtisticVacancyFromList',
    ADD_EDITED_VACANCY_IMAGE = 'addEditedVacancyImage',
    DELETE_EDITED_VACANCY_IMAGE_BY_INDEX = 'deleteEditedVacancyImageByIndex',
    DELETE_EDITED_VACANCY_IMAGE_BY_UUID = 'deleteEditedVacancyImageByUuid';

export {
    SET_ARTISTIC_VACANCIES,
    SET_ARTISTIC_VACANCIES_COUNT,
    SET_EDITED_ARTISTIC_VACANCY,
    UPDATE_ARTISTIC_VACANCY_IN_LIST,
    DELETE_ARTISTIC_VACANCY_FROM_LIST,
    ADD_EDITED_VACANCY_IMAGE,
    DELETE_EDITED_VACANCY_IMAGE_BY_INDEX,
    ADD_ARTISTIC_VACANCY,
    DELETE_EDITED_VACANCY_IMAGE_BY_UUID,
};
