import { SET_INSTRUMENTS } from './mutation-types';

export default {
    /**
    *
    * @param { object } state
    * @param { string } data
    */
    [SET_INSTRUMENTS](state, instruments) {
        state.instruments = instruments;
    },
};
