export default {
"80_90s": "80-90е",
"Accept the application?": "Принять заявку?",
"Add a comment to your application": "Добавьте комментарий к Вашей заявке",
"Additional promo links": "Дополнительные ссылки на промо",
"Application has been accepted by you": "Заявка была принята Вами",
"Application sent": "Заявка отправлена",
"Applications": "Заявки",
"Artist": "Артист",
"Can't reset password": "Невозможно изменить пароль",
"Cancel": "Отмена",
"Change password": "Изменить пароль.",
"Changes applied": "Изменения внесены",
"Changes saved": "Изменения схранены",
"Click to select": "Нажмите для выбора",
"Close the vacancy?": "Зарыть вакансию?",
"Confirm password": "Подтвердите пароль",
"Create vacancy": "Создать вакансию",
"Current password": "Текущий пароль",
"Date": "Дата",
"Description": "Описание",
"Did not get the email": "Не пришло письмо",
"Display vacancies for": "Показывать вакансии для",
"Email": "Почта",
"Email confirmed": "Имеил подтвержден",
"Event schedules": "Расписание мероприятий",
"Example links": "Ссылки на примеры",
"Favorite projects": "Избранные проекты",
"Fee": "Гонорар",
"Field is required": "Поле обязательно к заполнению",
"For artists": "Для артистов",
"For event agencies and art directors": "Ивент-агентствам и арт-директорам",
"Forget password?": "Забыли пароль?",
"Gallery": "Галерея",
"Go home": "На главную",
"Go to applications list?": "Перейти к списку заявок?",
"Go to relevant vacancies?": "Перейти к просмотру вакансий?",
"Go to the plans!": "Перейти к планам подиски",
"Hide the application?": "Спрятать заявку?",
"Home": "Главная",
"How it works": "Как это работает",
"I'm organizer": "Я организатор",
"Intelligent monitoring": "Интеллектуальный мониторинг",
"Link": "Ссылка",
"Link text": "Текст ссылки",
"Location": "Локация",
"Login": "Вход",
"Logout": "Выход",
"Main functionality": "Ключевой функционал",
"Message": "Сообщение",
"Name": "Имя",
"New password": "Новый пароль",
"No": "Нет",
"No messages": "Нет сообщений",
"No projects have been added to the collection": "Ни один проект не был добавлен в коллецию",
"Nothing here yet": "У вас нет активных проектов",
"Organization and productivity": "Организация и продуктивность",
"Organizer": "Организатор",
"Organizer profile": "Данные организатора",
"Password": "Пароль",
"Password successful changed": "Пароль успешно изменен",
"Password updated": "Пароль обновлен",
"Performer helps to improve the effectiveness of business communication, eliminate misunderstandings and organize contacts": "Performer помогает повысить эффективность делового общения, устранить недопонимание и организовать контакты.",
"Performer is a service of targeted delivery of your vacancies to artists. You create a vacancy - we make sure that exactly those artists who are most suitable for it see it.": "Performer - это сервис адресной доставки Ваших вакансий артистам. Вы создаете вакансию - мы следим за тем, чтобы ее увидели именно те артисты, которые для нее больше всего подходят.",
"Performer is the best place to find a job. Register, create your projects and find work in minutes and without intermediaries!": "Performer - лучшее место для поиска работы. Регистрируйтесь, создавайте свои проекты и находите работу за считанные минуты и без посредников!",
"Please confirm": "Подтвердите",
"Please fill out the Organizer's profile to be able to create vacancies": "Пожалуйста, заполните профиль Организатора, чтобы иметь возможность создавать вакансии",
"Please, check form fields": "Пожалуйста, проверьте поля формы",
"Please, wait a minute": "Будь ласка, зачекайте хвилину...",
"Position": "Должность",
"Profile name": "Название проекта",
"Project was updated": "Проект обновлен",
"Project without name": "Проект без названия",
"Publish": "Опубликовать",
"Ready for abroad contracts": "Готов к работе заграницей",
"Ready for work in other cities": "Готов к выезду в другие города",
"Receive up-to-date notifications about new vacancies or projects you are interested in": "Получайте актуальные уведомления о новых вакансиях или проектах, которые вас интересуют.",
"Register": "Регистрация",
"Registration": "Регистрация",
"Reject the application?": "Отклонить заявку?",
"Remember me": "Запомнить меня",
"Remove profile from favorites?": "Удалить проект из избранных?",
"Remove the organizer profile? All artists collections, conversations, vacancies will deleted": "Удалить профиль организатора? Все коллекции артистов, чаты, вакансии будут удалены.",
"Remove unpublished project?": "Удалить черновик?",
"Remove unpublished vacancy?": "Удалить черновик?",
"Request a proposal for a fee from a candidate": "Просить указать размер гонорара",
"Requirements": "Задача",
"Reset": "Восстановить",
"Reset link has been send": "Ссылка отправлена",
"Reset password": "Восстановить пароль",
"Riders": "Райдеры",
"Save": "Сохранить",
"Save password": "Сохранить пароль",
"Select": "Выбрать",
"Select city": "Выберите город",
"Select dancing styles": "Выберите танцевальные стили",
"Select instrument": "Выберите инструменты",
"Select instruments": "Выберите инструменты",
"Select instruments you need": "выберите инструменты которые вам нужны",
"Select instruments you use": "Выберите инструменты которые вы используете",
"Select musical styles": "Выберите музыкальные стили",
"Select profile type": "Выберите тип проекта",
"Select show type": "Выберите тип шоу",
"Select the main city of work": "Укажите основной город Вашей работы",
"Send": "Отправить",
"Send another one": "Отправить еще одно",
"Send application": "Откликнуться",
"Set new password": "Установить новый пароль",
"Show applications for": "Показать заявки для",
"Show type": "Тип шоу",
"Start free": "Начать бесплатно",
"Subscribe": "Подписка",
"Subscription": "Подписка",
"Subscription limit reached": "Достигнут лимит подписки",
"Such city does not exist": "Города с таким названием нет",
"Thank you for registration": "Спасибо за регистрацию",
"Thank you!": "Спасибо!",
"The project was not published": "Проект не был опубликован",
"The service allows you to easily organize your work schedule and keep your unique database of business contacts in perfect order": "Сервис позволяет легко организовать свой рабочий график и поддерживать свою уникальную базу деловых контактов в идеальном порядке.",
"The vacancy was not published": "Вакансия не была опубликована",
"There are no rejected applications yet": "Отклоненных заявок пока что нет",
"There are no suitable applications yet, but don't worry! We will send you a notification as soon as something appears": "Пока что подходящий предложений нет, но не волнуйтесь! Мы отправим вам уведомление, как только что-нибудь появится",
"There are no suitable vacancies yet, but don't worry! We will send you a notification as soon as something appears": "Подходящих вакансий пока нет, но не волнуйтесь! Мы отправим вам уведомление, как только что-нибудь появится",
"Update": "Обновить",
"Upload images": "Загрузить фотографии",
"Upload rider": "Загрузить райдер",
"Use the event planning system to create a schedule of artists performances for any period": "Воспользуйтесь системой планирования мероприятий, чтобы составить расписание выступлений артистов на любой период.",
"User profile": "Ваши данные",
"Vacancies": "Вакансии",
"Vacancy has been updated": "Вакансия обновлена",
"Verification email sent": "Письмо отправлено",
"We couldn't find this page": "Такой страницы не существует...",
"We do not show this information to third parties, but it will help us offer you the most suitable vacancies": "Мы не показываем эту информацию третьим лицам, но она поможет нам предлагать Вам наиболее подходящие вакансии",
"We have sent you an email to confirm your email address. Check your mail and follow the instructions.": "Вам отправлено письмо для подтверждения почты. Пожалуйста, проверьте Вашу почту",
"We sent a password reset link to the specified mail. Check your mail and follow the instructions": "Мы отправили ссылку для сброса пароля на указанную почту. Проверьте свою почту и следуйте инструкциям",
"Who are you looking for?": "Кого ищите?",
"With Performer, you no longer need to waste time tracking vacancies or finding new artists, these processes are automated!": "С Performer вам больше не нужно тратить время на отслеживание вакансий или поиск новых исполнителей, эти процессы автоматизированы!",
"Workplace": "Место работы",
"Yes": "Да",
"You can specify a lower threshold for the fee. We do not show this information to third parties, but it will help us offer you the most suitable vacancies.": "Вы можете указать нижний порог гонорара.Мы не показываем эту информацию третьим лицам, но она поможет нам предлагать Вам наиболее подходящие вакансии",
"You have no published vacancies": "У вас нет опубликованный вакансий",
"You have reached your vacancy application subscription limit": "Вы исчерпали лимит подписки на заявку на вакансию",
"You have {count} open vacancies": "У вас есть {count} открытых вакансий",
"You have {count} projects": "У вас есть {count} проектов",
"You've Chosen the {plan} plan": "Вы выбрали подписку {plan}",
"Your Facebook profile link": "Фейсбук профиль",
"Your Instagram profile link": "Инстаграм профиль",
"Your application": "Ваша заявка",
"Your application has been accepted": "Ваша заявка принята",
"Your best promo": "Лучшее промо",
"Your email": "Ваш email",
"Your message has been sent": "Ваше сообщение было отправлено",
"Your name, nickname or team name": "Ваше имя, никнейм или название проекта",
"Your new password": "Ваш новый пароль",
"Your payment offer": "Ваше предложение по оплате",
"Your projects": "Ваши проекты",
"Youtube link on your best or latest promo video": "ССылка на Ваше лучшее или самое свежее промо",
"accept": "принять",
"accepted": "принята",
"acoustic_drums": "аккустические барабаны",
"acoustic_guitar": "аккустическая гитара",
"acrobatic": "Акробатическое",
"add": "добавить",
"agreed": "договорной",
"agreed budget": "договорной бюджет",
"agreed fee": "договорной бюджет",
"all projects": "все проекты",
"all vacancies": "все вакансии",
"amount": "сумма",
"animator": "Аниматор",
"application accepted": "заявка принята",
"application rejected": "заявка отклонена",
"application sent": "заявка отправлена",
"applications": "заявки",
"archive": "в архив",
"b_boxing": "бит-бокс",
"back": "назад",
"ballet": "Балет",
"band": "Группа",
"bandura": "бандура",
"barman": "Бармен",
"bass_guitar": "басс гитара",
"bavarian": "Баварская",
"break_dance": "Брейк",
"cajon": "кахон",
"cello": "виолончель",
"change": "сменить",
"city": "город",
"classic": "Классическая",
"clear": "очистить",
"clear errors": "очистить ошибки",
"close": "закрыть",
"continue": "Продолжить",
"country": "Country",
"create new project": "создать проект",
"create vacancy": "создать вакансию",
"dance": "Танцевальное",
"dancer": "Танцор",
"deep_house": "Deep house",
"delete": "удалить",
"describe the requirements in details": "как можно детальнее опишите задачу",
"description": "описание",
"details": "подробнее",
"dj": "DJ",
"dj_instrumental": "DJ инструменталист",
"double_bass": "контрабас",
"draft": "черновик",
"east": "Восточный",
"edit": "редактировать",
"electric_drums": "электро барабаны",
"electric_guitar": "электрогитара",
"enter the result of the expression": "введите результат выражения...",
"examples": "примеры",
"fee": "оплата",
"fire": "Файер",
"fixed": "фиксированный",
"flute": "флейта",
"folk": "Народный",
"for all vacancies": "всех вакансий",
"for vacancy": "для вакансии",
"freak": "Фрик",
"french": "Французская",
"funk": "Funk",
"go back": "назад",
"go-go": "Go-go",
"guitar": "гитара",
"gymnast": "Гимнаст",
"hang": "ханг",
"hide": "скрыть",
"hide details": "скрыть детали",
"host": "Ведущий",
"ice": "Ледовое",
"illusionist": "Иллюзионист",
"indicate a place of work": "укажите место работы",
"indie_rock": "Indie-Rock",
"instruments": "инструменты",
"is abroad contract": "работа заграницей",
"italian": "Итальянская",
"jazz": "Jazz",
"keys": "клавишные",
"laser": "Лазерное",
"latin": "Латина",
"led": "Лэд-шоу",
"light": "Световое",
"links": "ссылки",
"lounge": "Lounge",
"master_class": "Мастер-класс",
"mc": "MC",
"music": "Музыкальное",
"original_genre": "Оригинальный жанр",
"percussion": "перкуссия",
"pop": "Pop",
"profile type": "тип проекта",
"punk_rock": "Punk-Rock",
"pyrotechnic": "Пиротехническое",
"r&b": "R&B",
"reject": "отклонить",
"rejected": "отклонено",
"remove": "удалить",
"requirements": "требования",
"resend": "отправить снова",
"rock": "Rock",
"sand_animation": "Песочная анимация",
"sax": "саксофон",
"search all over Ukraine": "принимать заявки со всей Украины",
"select dancing styles you need": "выберите танцевальные стили которые вам нужны",
"select musical styles you need": "выберите музыкальные стили которые вам нужны",
"send message": "написать сообщение",
"show": "Шоу",
"show all": "показать все",
"show more": "больше",
"show rejected applications": "показать отклоненные заявки",
"singer": "Вокальное",
"solo_performer": "Сольный исполнитель",
"soul": "Soul",
"spd": "spd",
"stand_up": "Stand-Up комик",
"strip": "Стрип",
"styles": "стили",
"swing": "Свинг",
"tap_dance": "Чечетка",
"theatrical": "Театральное",
"trombone": "тромбон",
"trumpet": "труба",
"tuba": "туба",
"type city name": "начните вводить название города...",
"ukrainian": "Украинская",
"ukulele": "укулеле",
"unpublished vacancy": "неопубликованная вакансия",
"uploading error": "ошибка загрузки",
"viola": "альт",
"violin": "скрипка",
"vocal": "вокал",
"waiting for downloads": "ожидают загрузки",
"world_hits": "Мировые хиты",
"Сomplaints & Suggestions": "Жалобы и Предложения"};