import { GET_INSTRUMENTS } from './action-types';
import axios from "@/plugins/axios";
import {SET_INSTRUMENTS} from "./mutation-types";

/**
*
* @param { function } commit
* @param { string } data
*/
export default {
    async [GET_INSTRUMENTS]({commit}) {
        return axios.get('data-for-vacancy-or-artistic-profile-creation')
            .then(res => {
                let { instruments } = res.data;
                commit(SET_INSTRUMENTS, instruments);
                return res;
            }).catch(errors => {
                return errors;
            });
    }
};
