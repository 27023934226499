import {
    SET_APPLICATION_LOCALE,
    SET_APPLICATION_TYPES,
    SET_UPDATES_AVAILABLE,
    SET_WORKER_REGISTRATION
} from "@/store/modules/base/mutation-types";

export default {

    [SET_UPDATES_AVAILABLE](state, data) {
        state.updatesAvailable = data;
    },
    [SET_WORKER_REGISTRATION](state, data){
        state.workerRegistration = data;
    },
    [SET_APPLICATION_TYPES](state, data){
        state.applicationTypes = data;
    },
    [SET_APPLICATION_LOCALE](state, locale){
        state.locale = locale;
    }
};
