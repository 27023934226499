import { GET_CITIES } from './action-types';
import axios from "@/plugins/axios";
import {SET_CITIES} from "./mutation-types";

/**
*
* @param { function } commit
* @param { string } data
*/
export default {
    async [GET_CITIES]({commit}) {
        return axios.get('cities')
            .then(res => {
                commit(SET_CITIES, res.data);
                return res;
            }).catch(errors => {
                return errors;
            });
    }
};
