export default {
"80_90s": "80-90е",
"Accept the application?": "Прийняти пропозицію?",
"Add a comment to your application": "Додайте коментар до своєї пропозиції",
"Additional promo links": "Додаткові промо-посилання",
"Application has been accepted by you": "Заявка прийнята Вами",
"Application sent": "Пропозиція надіслана",
"Applications": "Пропозиції на вакансії",
"Artist": "Артист",
"Can't reset password": "Неможливо змінити пароль",
"Cancel": "Скасувати",
"Change password": "Змінити пароль",
"Changes applied": "Зміни застосовані",
"Changes saved": "Зміни збережені",
"Click to select": "Натисніть щоб вибрати",
"Close the vacancy?": "Закрити вакансію?",
"Confirm password": "Повторіть пароль",
"Create vacancy": "Create vacancy",
"Current password": "Поточний пароль",
"Date": "Дата",
"Description": "Опис",
"Did not get the email": "Не отримав електронний лист",
"Display vacancies for": "Показати вакансії для",
"Email": "Пошта",
"Email confirmed": "Електронну адресу підтверджено",
"Event schedules": "Розклад заходів",
"Example links": "Посилання на приклади",
"Favorite projects": "Обрані проекти",
"Fee": "Гонорар",
"Field is required": "Поле обов’язкове",
"For artists": "Для артистів",
"For event agencies and art directors": "Івент-агентствам і арт-директорам",
"Forget password?": "Забули пароль?",
"Gallery": "Галерея",
"Go home": "На головну",
"Go to applications list?": "Перейти до списку заявок?",
"Go to relevant vacancies?": "Перейти до відповідних вакансій?",
"Go to the plans!": "Перейти до планів підписки",
"Hide the application?": "Сховати пропозицію?",
"Home": "Головна",
"How it works": "Як це працює",
"I'm organizer": "Я організатор",
"Intelligent monitoring": "Інтелектуальний моніторинг",
"Link": "Посилання",
"Link text": "Текст посилання",
"Location": "Локація",
"Login": "Вхід",
"Logout": "Вихід",
"Main functionality": "Ключовий функціонал",
"Message": "Повідомлення",
"Name": "Ім'я",
"New password": "Новий пароль",
"No": "Ні",
"No messages": "Немає повідомлень",
"No projects have been added to the collection": "Жоден проект не був доданий до колекції",
"Nothing here yet": "У вас немає активних проектів",
"Organization and productivity": "Організація і продуктивність",
"Organizer": "Організатор",
"Organizer profile": "Профіль організатора",
"Password": "Пароль",
"Password successful changed": "Пароль успішно змінено",
"Password updated": "Пароль змінено",
"Performer helps to improve the effectiveness of business communication, eliminate misunderstandings and organize contacts": "Performer допомагає підвищити ефективність ділового спілкування, усунути непорозуміння та організувати контакти.",
"Performer is a service of targeted delivery of your vacancies to artists. You create a vacancy - we make sure that exactly those artists who are most suitable for it see it.": "Performer - це сервіс адресної доставки Ваших вакансій артистам. Ви створюєте вакансію - ми стежимо за тим, щоб її побачили саме ті артисти, які для неї найбільше підходять.",
"Performer is the best place to find a job. Register, create your projects and find work in minutes and without intermediaries!": "Performer - найкраще місце для пошуку роботи. Реєструйтеся, створюйте свої проекти та знаходьте роботу за лічені хвилини та без посередників!",
"Please confirm": "Підтвердіть",
"Please fill out the Organizer's profile to be able to create vacancies": "Будь ласка, заповніть профіль Організатора, щоб мати можливість створювати вакансії",
"Please, check form fields": "Будь ласка, перевірте поля форми",
"Please, wait a minute": "Пожалуйста, попробуйте через минуту...",
"Position": "Посада",
"Profile name": "Назва проекту",
"Project was updated": "Проект оновлено",
"Project without name": "Проект без назви",
"Publish": "Опублікувати",
"Ready for abroad contracts": "Готові до закордонних контрактів",
"Ready for work in other cities": "Готовий до роботи в інших містах",
"Receive up-to-date notifications about new vacancies or projects you are interested in": "Отримуйте актуальні повідомлення про нові вакансії або артистів, які вас цікавлять.",
"Register": "Реєстрація",
"Registration": "Реєстрація",
"Reject the application?": "Відхилити пропозицію?",
"Remember me": "Запам'яти мене",
"Remove profile from favorites?": "Видалити проект iз обраних?",
"Remove the organizer profile? All artists collections, conversations, vacancies will deleted": "Видалити профіль організатора? Усі колекції виконавців, чати, вакансії будуть видалені.",
"Remove unpublished project?": "Видалити чернетку?",
"Remove unpublished vacancy?": "Видалити чернетку?",
"Request a proposal for a fee from a candidate": "Запитувати пропозицію щодо винагороди у кандидата",
"Requirements": "Вимоги",
"Reset": "Відновити",
"Reset link has been send": "Посилання відправлено",
"Reset password": "Відновити пароль",
"Riders": "Райдери",
"Save": "Зберегти",
"Save password": "Зберегти пароль",
"Select": "Обрати",
"Select city": "Оберіть місто",
"Select dancing styles": "Оберіть стилі танців",
"Select instrument": "Вкажіть інструменти",
"Select instruments": "Оберіть інструменти",
"Select instruments you need": "Оберіть потрібні вам інструменти",
"Select instruments you use": "Вкажіть інструменти які використовуються",
"Select musical styles": "Вкажіть музичні стилі",
"Select profile type": "Оберіть тип проекту",
"Select show type": "Оберіть тип шоу",
"Select the main city of work": "Вкажіть основне місто Вашої роботи",
"Send": "Відправити",
"Send another one": "Відправити ще одне",
"Send application": "Надіслати пропозицію",
"Set new password": "Встановити новий пароль",
"Show applications for": "Показати програми для",
"Show type": "Тип шоу",
"Start free": "Розпочати безкоштовно",
"Subscribe": "Підписка",
"Subscription": "Передплата",
"Subscription limit reached": "Досягнуто ліміту підписки",
"Such city does not exist": "Такого міста не існує",
"Thank you for registration": "Дякуємо за реєстрацію",
"Thank you!": "Дякуємо!",
"The project was not published": "Проект не опублікуваний",
"The service allows you to easily organize your work schedule and keep your unique database of business contacts in perfect order": "Сервіс дозволяє легко організувати свій робочий графік і підтримувати свою унікальну базу ділових контактів в ідеальному стані.",
"The vacancy was not published": "Вакансія не була опублікована",
"There are no rejected applications yet": "Відхилених заявок поки немає",
"There are no suitable applications yet, but don't worry! We will send you a notification as soon as something appears": "Поки що немає відповідних пропозицій, але не хвилюйтеся! Ми надішлемо вам сповіщення, щойно щось з’явиться",
"There are no suitable vacancies yet, but don't worry! We will send you a notification as soon as something appears": "Вакансій поки що немає, але не хвилюйтеся! Ми надішлемо вам сповіщення, щойно щось з’явиться",
"Update": "Оновити",
"Upload images": "Завантажити зображення",
"Upload rider": "Завантажити райдер",
"Use the event planning system to create a schedule of artists performances for any period": "Скористайтеся системою планування, щоб скласти розклад виступів артистів на будь-який період.",
"User profile": "Обліковий запис",
"Vacancies": "Вакансії",
"Vacancy has been updated": "Вакансія була оновлена",
"Verification email sent": "Електронний лист із підтвердженням надіслано",
"We couldn't find this page": "Не вдалося знайти цю сторінку ...",
"We do not show this information to third parties, but it will help us offer you the most suitable vacancies": "Ми не передаємо цю інформацію третім особам, але це допоможе нам запропонувати вам найбільш відповідні вакансії.",
"We have sent you an email to confirm your email address. Check your mail and follow the instructions.": "Ми надіслали Вам електронний лист для підтвердження вашої адреси електронної пошти. Перевірте пошту та дотримуйтесь інструкцій.",
"We sent a password reset link to the specified mail. Check your mail and follow the instructions": "Ми надіслали посилання для відновлення пароля на вказану пошту. Перевірте пошту та дотримуйтесь інструкцій",
"Who are you looking for?": "Кого Ви шукаєте?",
"With Performer, you no longer need to waste time tracking vacancies or finding new artists, these processes are automated!": "З Performer вам більше не потрібно витрачати час на відстеження вакансій або пошук нових виконавців, ці процеси автоматизовані!",
"Workplace": "Місце работи",
"Yes": "Так",
"You can specify a lower threshold for the fee. We do not show this information to third parties, but it will help us offer you the most suitable vacancies.": "Ви можете вказати поріг плати. Ми не передаємо цю інформацію третім особам, але це допоможе нам запропонувати вам найбільш відповідні вакансії.",
"You have no published vacancies": "У вас немає опублікаваних вакансій",
"You have reached your vacancy application subscription limit": "Ви досягли ліміту підписки на заявку на вакансію",
"You have {count} open vacancies": "У вас є {count} відкритих вакансій",
"You have {count} projects": "У вас є {count} проектів",
"You've Chosen the {plan} plan": "Ви обрали план {plan}",
"Your Facebook profile link": "Посилання на ваш профіль у Facebook",
"Your Instagram profile link": "Посилання на ваш профіль в Instagram",
"Your application": "Ваша пропозиція",
"Your application has been accepted": "Вашу заявку прийнято",
"Your best promo": "Ваше найкраще промо",
"Your email": "Ваш email",
"Your message has been sent": "Ваше повідомлення було відправлено",
"Your name, nickname or team name": "Ваше ім'я, псевдонім або назва команди",
"Your new password": "Ваш новий пароль",
"Your payment offer": "Ваша пропозиція щодо винагороди",
"Your projects": "Ваші проекти",
"Youtube link on your best or latest promo video": "Посилання на Youtube на ваше найкраще або останнє промо-відео",
"accept": "прийняти",
"accepted": "прийнята",
"acoustic_drums": "акустичні барабани",
"acoustic_guitar": "акустична гітара",
"acrobatic": "Акробатичне",
"add": "додати",
"agreed": "по домовленості",
"agreed budget": "бюджет по домовленості",
"agreed fee": "бюджет по домовленості",
"all projects": "всі проекти",
"all vacancies": "всі вакансії",
"amount": "сума",
"animator": "Аніматор",
"application accepted": "пропозиція прийнята",
"application rejected": "пропозиція відхилена",
"application sent": "пропозицію надіслано",
"archive": "архівувати",
"b_boxing": "біт-бокс",
"back": "назад",
"ballet": "Балет",
"band": "Гурт",
"bandura": "бандура",
"barman": "Бармен",
"bass_guitar": "бас-гітара",
"bavarian": "Баварская",
"break_dance": "Брейк",
"cajon": "кахон",
"cello": "віолончель",
"change": "змінити",
"city": "місто",
"classic": "Класична",
"clear": "очистити",
"clear errors": "очистити помилки",
"close": "закрити",
"continue": "Продовжити",
"country": "Country",
"create new project": "створити новий проект",
"create vacancy": "створити вакансію",
"dance": "Танцювальне",
"dancer": "Танцор",
"deep_house": "Deep house",
"delete": "видалити",
"describe the requirements in details": "детально опишіть вимоги",
"description": "опис",
"details": "детальніше",
"dj": "DJ",
"dj_instrumental": "DJ інструменталіст",
"double_bass": "контрабас",
"draft": "чернетка",
"east": "Східний",
"edit": "редагувати",
"electric_drums": "електричні барабани",
"electric_guitar": "електрогітара",
"enter the result of the expression": "введіть результат виразу...",
"examples": "приклади",
"fee": "оплата",
"fire": "Фаєр-шоу",
"fixed": "фіксований",
"flute": "флейта",
"folk": "Народний",
"for all vacancies": "всіх вакансій",
"for vacancy": "для вакансії",
"freak": "Фрік-шоу",
"french": "Французська",
"funk": "Funk",
"go back": "назад",
"go-go": "Go-go",
"guitar": "гітара",
"gymnast": "Гімнаст",
"hang": "ханг",
"hide": "сховати",
"hide details": "приховати деталі",
"host": "Ведучий",
"ice": "Льодове",
"illusionist": "Ілюзіоніст",
"indicate a place of work": "вказати місце роботи",
"indie_rock": "Indie-Rock",
"instruments": "інструменти",
"is abroad contract": "закордонний контракт",
"italian": "Італійська",
"jazz": "Jazz",
"keys": "клавіші",
"laser": "Лазерне",
"latin": "Латина",
"led": "Лед-шоу",
"light": "Світлове",
"links": "посилання",
"lounge": "Lounge",
"master_class": "Майстер-клас",
"mc": "MC",
"music": "Музичне",
"original_genre": "Оригінальний жанр",
"percussion": "перкусія",
"pop": "Pop",
"profile type": "тип проекту",
"punk_rock": "Punk-Rock",
"pyrotechnic": "Піротехнічне",
"r&b": "R&B",
"reject": "відхилити",
"rejected": "відхилено",
"remove": "видалити",
"requirements": "вимоги",
"resend": "відправити знову",
"rock": "Rock",
"sand_animation": "Пісочна анімація",
"sax": "саксофон",
"search all over Ukraine": "пошук по всій Україні",
"select dancing styles you need": "оберіть потрібні вам стилі танців",
"select musical styles you need": "оберіть потрібні музичні стилі",
"send message": "відправити повідомлення",
"show": "Шоу",
"show all": "показати всі",
"show more": "більше",
"show rejected applications": "показати відхилені заявки",
"singer": "Вокальне",
"solo_performer": "Сольний виконавець",
"soul": "Soul",
"spd": "spd",
"stand_up": "Stand-Up комік",
"strip": "Стрип",
"styles": "стилі",
"swing": "Свінг",
"tap_dance": "Чечітка",
"theatrical": "Театральне",
"trombone": "тромбон",
"trumpet": "труба",
"tuba": "туба",
"type city name": "введіть назву міста...",
"ukrainian": "Українська",
"ukulele": "укулеле",
"unpublished vacancy": "неопублікована вакансія",
"uploading error": "помилка завантаження",
"viola": "альт",
"violin": "скрипка",
"vocal": "вокал",
"waiting for downloads": "очікують завантаження",
"world_hits": "Всесвістні хіти",
"Сomplaints & Suggestions": "Скарги та Пропозиції"};