import {
    REMOVE_RELEVANT_VACANCY_FROM_LIST,
    SET_ARTISTIC_PROFILE_RELEVANT_VACANCIES_PAGINATION,
    SET_FILTER_TARGET_ARTISTIC_PROFILE_ID,
    SET_RELEVANT_VACANCIES,
    SET_VIEWABLE_RELEVANT_VACANCY
} from './mutation-types';

export default {
    /**
    *
    * @param { object } state
    * @param { object } vacancy
    */
    [SET_VIEWABLE_RELEVANT_VACANCY](state, vacancy) {
        state.viewable = vacancy;
    },
    [SET_RELEVANT_VACANCIES](state, vacancies){
        state.vacancies = vacancies;
    },
    [REMOVE_RELEVANT_VACANCY_FROM_LIST](state, index){
        state.vacancies.splice(index, 1);
    },
    [SET_ARTISTIC_PROFILE_RELEVANT_VACANCIES_PAGINATION](state, paginationMeta){
        state.artisticProfileRelevantVacanciesPagination = paginationMeta;
    },
    [SET_FILTER_TARGET_ARTISTIC_PROFILE_ID](state, artisticProfileId){
        state.filterTargetArtisticProfileId = artisticProfileId;
    }
};
