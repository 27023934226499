import {SET_SUBSCRIPTION_PLANS} from './mutation-types';

export default {
    /**
     *
     * @param { object } state
     * @param subscriptionPlans
     */
    [SET_SUBSCRIPTION_PLANS](state, subscriptionPlans) {
        state.subscriptionPlans = subscriptionPlans;
    },
};
