<template>
    <b-select size="is-small" class="mt-3 ml-3" v-model="form.locale" @input="handleChangeLocale">
        <option v-for="locale in supportedLocales"
                class="pr-0"
                :key="locale"
                :value="locale">{{locale}}</option>
    </b-select>
</template>

<script>
import Form from "vform";
import {SET_APPLICATION_LOCALE} from "@/store/modules/base/mutation-types";
import {mapMutations} from "vuex";

export default {
    data(){
        return {
            form: new Form({
                locale: 'uk',
            }),
            supportedLocales: ['uk', 'en'],
        };
    },
    methods: {
        ...mapMutations('base', [
            SET_APPLICATION_LOCALE,
        ]),
        handleChangeLocale(){
            this.$root.$i18n.locale = this.form.locale;
            localStorage.setItem('locale', this.form.locale);
            this[SET_APPLICATION_LOCALE](this.form.locale);
        },
    },
    created() {
        if (localStorage.getItem('locale')){
            this.$root.$i18n.locale = this.form.locale = localStorage.getItem('locale');
            this[SET_APPLICATION_LOCALE](this.form.locale);
        } else {
          this.$root.$i18n.locale = this.form.locale = 'uk';
          localStorage.setItem('locale', 'uk');
        }
    }
};
</script>

<style scoped>

</style>