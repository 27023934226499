<template>

    <b-navbar class="is-relative is-transparent mb-3 ">
        <template slot="brand">

          <b-navbar-item data-cy="header-text-logo" tag="router-link" :to="{ path: '/' }" class="is-hidden-mobile pl-0">
            <h1 class="title is-5 has-text-primary">Performer</h1>
<!--                <img src="../assets/performer.png">-->
          </b-navbar-item>
          <template v-if="isAuthenticated">
            <b-navbar-item tag="router-link" :to="{ name: 'artisticDashboard'}">{{$t('Artist')}}</b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ name: 'organizerDashboard'}">{{$t('Organizer')}}</b-navbar-item>
          </template>

          <change-locale class="is-hidden-desktop" />


        </template>
        <template slot="start">


        </template>
        <template slot="end">

          <template v-if="!isAuthenticated">
            <b-navbar-item tag="router-link"
                           :to="{name: 'home'}">
              {{$t('Home')}}
            </b-navbar-item><b-navbar-item tag="router-link"
                           :to="{name: 'login'}">
              {{$t('Login')}}
            </b-navbar-item>
            <b-navbar-item tag="router-link"
                           :to="{name: 'register'}">
              {{$t('Register')}}
            </b-navbar-item>
          </template>
          <change-locale class="is-hidden-touch mx-3" />

          <!--            <app-install-promotion v-if="!isAuthenticated" />-->
            <b-navbar-dropdown :label="user.name" v-if="isAuthenticated">
                <b-navbar-item tag="router-link" :to="{ name: 'userProfile'}">{{$t('User profile')}}</b-navbar-item>
                <b-navbar-item tag="router-link" v-if="!subscriptionDisabled" :to="{ name: 'subscription'}">{{$t('Subscribe')}}</b-navbar-item>
<!--                <app-install-promotion />-->
                <b-navbar-item tag="a" @click="handleLogout">{{$t('Logout')}}</b-navbar-item>
            </b-navbar-dropdown>

        </template>
    </b-navbar>
</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";
    import {LOGIN, LOGOUT} from "@/store/modules/auth/action-types";
    import subscriptionDisabledMixin from "@/mixins/subscriptionDisabledMixin";
    import ChangeLocale from "@/components/ChangeLocale";

    export default {
        name: "NavBar",
        components:{
            ChangeLocale,
        },
        data(){
            return {
              socetId: null,
            };
        },
        methods: {
            ...mapActions('auth',[
                LOGIN,
                LOGOUT,
            ]),
            handleLogout(){
                this[LOGOUT]().then(() => {
                    this.$router.push({name: 'login'});
                });
            },

        },
        computed: {
            ...mapState({
                'user' : state => state.auth.user,
            }),
            ...mapGetters('auth',['isAuthenticated']),
        },
      mixins: [subscriptionDisabledMixin],
    };
</script>

<style scoped>
    .router-link-active{
        color: #7957d5;
        font-weight: bold;
    }

</style>