/** @var { ChatState } */
const STATE = {
    data: null,
    participantsTypes: {
        organizer: "OrganizerProfile",
        artist: "ArtisticProfile",
        application: 'ArtisticApplication',
    },
};

export default STATE;
