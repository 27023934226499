const STATE = {
    applications: null,
    organizerApplications: null,
    filter: {
        status: 'sent,accepted',
        forVacancy: null,
    }
};

export default STATE;
