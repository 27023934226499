const SET_USER = 'setUser',
    SET_USER_ATTRIBUTE = 'setUserAttribute',
    SET_USER_SUBSCRIPTION = 'setUserSubscription',
    DECREMENT_VACANCIES_LEFT_LIMIT = 'decrementVacanciesLeftLimit';

export {
    SET_USER,
    SET_USER_SUBSCRIPTION,
    SET_USER_ATTRIBUTE,
    DECREMENT_VACANCIES_LEFT_LIMIT,
};
