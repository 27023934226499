import {
    GET_ARTISTIC_APPLICATIONS,
    ARCHIVE_ARTISTIC_APPLICATION,
    CREATE_ARTISTIC_APPLICATION,
    REJECT_ARTISTIC_APPLICATION, ACCEPT_ARTISTIC_APPLICATION, GET_ORGANIZER_ARTISTIC_APPLICATIONS
} from './actions-types';
import {
    ADD_ARTISTIC_APPLICATION,
    REMOVE_ORGANIZER_ARTISTIC_APPLICATION_FROM_LIST,
    SET_ARTISTIC_APPLICATIONS,
    SET_ORGANIZER_ARTISTIC_APPLICATIONS,
    UPDATE_ORGANIZER_ARTISTIC_APPLICATION_STATUS_IN_LIST,
} from "./mutation-types";
import axios from '@/plugins/axios';

export default {
    [GET_ARTISTIC_APPLICATIONS]({commit}){
        return new Promise((resolve, reject) => {
            axios.get('artistic-applications')
                .then(res => {
                    commit(SET_ARTISTIC_APPLICATIONS,res.data);
                    resolve(res);
                }).catch(errors => {
                    reject(errors);
            });
        });
    },
    async [GET_ORGANIZER_ARTISTIC_APPLICATIONS]({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get('organizer-artistic-applications', {params: {...params}})
                .then(res => {
                    commit(SET_ORGANIZER_ARTISTIC_APPLICATIONS, res.data.data);
                    resolve(res);
                }).catch(errors => {
                reject(errors);
            });
        });
    },
    [ARCHIVE_ARTISTIC_APPLICATION]({commit=commit}, applicationId){
        return new Promise((resolve, reject) => {
            axios.put(`artistic-applications/${applicationId}/archive-for-artistic-profile`)
                .then(res => {
                    resolve(res);
                }).catch(errors => {
                    reject(errors);
            });
        });
    },
    [CREATE_ARTISTIC_APPLICATION]({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post('artistic-profiles/' + payload.artisticProfileId + '/artistic-applications', payload.applicationData)
                .then(res => {
                    commit(ADD_ARTISTIC_APPLICATION, res.data);
                    resolve(res);
                }).catch(errors => {
                reject(errors);
            });
        });
    },
    [REJECT_ARTISTIC_APPLICATION]({commit}, applicationId){
        return new Promise((resolve, reject) => {
            axios.post(`organizer-artistic-applications/${applicationId}/refuse`)
                .then(res => {
                    commit(REMOVE_ORGANIZER_ARTISTIC_APPLICATION_FROM_LIST, applicationId);
                    resolve(res);
                }).catch(errors => {
                    reject(errors);
                });
        });
    },
    [ACCEPT_ARTISTIC_APPLICATION]({commit}, applicationId){
        return new Promise((resolve, reject) => {
            axios.post(`organizer-artistic-applications/${applicationId}/accept`)
                .then(res => {
                    commit(UPDATE_ORGANIZER_ARTISTIC_APPLICATION_STATUS_IN_LIST, {
                        applicationId: applicationId,
                        status: 'accepted',
                    });
                    resolve(res);
                }).catch(errors => {
                    reject(errors);
                });
        });
    }
};
