import {
    SET_ARTISTIC_VACANCIES,
    SET_ARTISTIC_VACANCIES_COUNT,
    SET_EDITED_ARTISTIC_VACANCY,
    UPDATE_ARTISTIC_VACANCY_IN_LIST,
    DELETE_ARTISTIC_VACANCY_FROM_LIST,
    ADD_EDITED_VACANCY_IMAGE,
    DELETE_EDITED_VACANCY_IMAGE_BY_INDEX,
    ADD_ARTISTIC_VACANCY, DELETE_EDITED_VACANCY_IMAGE_BY_UUID,
} from './mutation-types';
import Vue from 'vue';

export default {
    [SET_ARTISTIC_VACANCIES](state, vacancies) {
        state.vacancies = vacancies;
    },
    [SET_ARTISTIC_VACANCIES_COUNT](state, count){
        state.vacanciesCount = count;
    },
    [SET_EDITED_ARTISTIC_VACANCY](state, vacancy){
        state.editedVacancy = vacancy;
    },
    [UPDATE_ARTISTIC_VACANCY_IN_LIST](state, vacancy){
        let index = state.vacancies.findIndex(v => v.id === vacancy.id);
        state.vacancies.splice(index, 1, vacancy);
    },
    [DELETE_ARTISTIC_VACANCY_FROM_LIST](state, vacancyId){
        let index = state.vacancies.findIndex(v => v.id === vacancyId);
        state.vacancies.splice(index, 1);
    },
    [ADD_EDITED_VACANCY_IMAGE](state, payload){

        if (Array.isArray(state.editedVacancy.images)){
            state.editedVacancy.images = {};
        }

        Vue.set(state.editedVacancy.images, payload.uuid, payload);

        // if (Array.isArray(payload)){
        //     state.editedVacancy.images.concat(payload);
        // } else {
        //     state.editedVacancy.images.push(payload);
        // }
    },
    [DELETE_EDITED_VACANCY_IMAGE_BY_INDEX](state, index){
        state.editedVacancy.images.splice(index,1);
    },
    [DELETE_EDITED_VACANCY_IMAGE_BY_UUID](state, uuid){
        Vue.delete(state.editedVacancy.images, uuid);
    },
    [ADD_ARTISTIC_VACANCY](state, vacancy){
        if (Array.isArray(state.vacancies)){
            state.vacancies.push(vacancy);
        } else {
            state.vacancies = [vacancy];
        }
    }
};
