export default {
    data(){
        return {
            emails: [
                'sam.smith@hotmail.com',
                'mrs-rihanna@gmail.com',
                'zibrembo@ukr.net',
                'timberlake-man@usa.biz',
            ]
        };
    },
    methods: {
        $_p_getRandomEmailPlaceholder(){
            return this.emails[this.$_performer_getRandomInteger(0, this.emails.length - 1)];
        },
        $_performer_getRandomInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1) ) + min;
        }
    }
};