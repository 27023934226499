import { GET_ARTISTIC_PROFILE_TYPES } from './action-types';
import {SET_ARTISTIC_PROFILE_TYPES} from "./mutation-types";
import axios from '@/plugins/axios';
/**
*
* @param { function } commit
* @param { string } data
*/
export default {
    async [GET_ARTISTIC_PROFILE_TYPES]({commit}) {
        return axios.get('data-for-vacancy-or-artistic-profile-creation')
            .then(res => {
                let { artistic_profile_types} = res.data;
                commit(SET_ARTISTIC_PROFILE_TYPES, artistic_profile_types);
                return res;
            }).catch(errors => {
                return errors;
            });
    }
};
