<template>
    <div class="container">
        <div class="columns is-centered">
            <div class="column is-half-fullhd">
                <div class="box">

                    <b-message v-if="$route.query.email_verified" :title="$t('Email confirmed')" type="is-success" :aria-close-label="$t('Close')">
                        {{$t('Now you can log in using your email and password')}}
                    </b-message>
                    <b-message v-if="$route.query.already_verified" :title="$t('Email already confirmed')" type="is-warning" :aria-close-label="$t('Close')">
                        {{$t('Now you can log in using your email and password')}}
                    </b-message>

                    <form action="" @submit.prevent="">
                        <h4 data-cy="login-form-title" class="title is-4">{{$t('Login')}}</h4>

                        <b-field :label="$t('Email')"
                                 :type="{'is-danger': $_p_formHasError('email')}">
                            <b-input v-model="email"
                                     data-cy="email"
                                     type="email"
                                     icon="envelope"
                                     name="email"
                                     @input="authError = false"
                                     :placeholder="emailPlaceholder">
                            </b-input>
                        </b-field>

                        <b-field :label="$t('Password')"
                                 :message="$_p_formGetError('email')"
                                 :type="{'is-danger':  $_p_formHasError('email')}">
                            <b-input type="password"
                                     @keyup.enter.native="handleLogin"
                                     data-cy="password"
                                     name="password"
                                     @input="authError = false"
                                     placeholder="********"
                                     v-model="password"
                                     password-reveal
                                     icon="lock">
                            </b-input>
                        </b-field>

                        <b-field>
                            <b-checkbox v-model="remember">{{$t('Remember me')}}</b-checkbox>
                        </b-field>

                        <b-field >
                            <div class="level is-mobile">
                                <div class="level-left">
                                    <router-link :to="{name: 'passwordReset'}" class="level-item"
                                                 data-cy="password-recovery-link">{{$t('Forget password?')}}
                                    </router-link>
                                </div>
                                <div class="level-right ">
                                    <b-button data-cy="submit-login"
                                              type="is-primary"
                                              :loading="formBusy"
                                              @click="handleLogin">{{$t('Login')}}</b-button>
                                </div>
                            </div>

                        </b-field>

                    </form>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import getRandomEmailPlaceholderMixin from "@/mixins/getRandomEmailPlaceholderMixin";
    import {mapActions} from "vuex";
    import {LOGIN} from "@/store/modules/auth/action-types";
    import formHelperMixin from "@/mixins/formHelperMixin";

    export default {
        data(){
            return {
                email: '',
                password: '',
                remember: false,
                authError: false,
                emailPlaceholder: null,
            };
        },
        methods: {
            ...mapActions('auth', [
                LOGIN
            ]),
            handleLogin(){
                this.busy = true;
                this.$_p_formDispatch(LOGIN, this.credentials)
                    .then((res) => {
                        this.$gEvent('login', {userId: res.data.id});
                        this.goToMainPage(res.data);
                    }).catch(error => {
                        this.$gEvent('login_fail', {userEmail: this.credentials.email, error});
                    });
            },
            goToMainPage(user){
                if (user.is_organizer){
                    this.$router.push({name: 'organizerDashboard'});
                } else  {
                    this.$router.push({name: 'artisticDashboard'});
                }
            },
        },
        computed: {
            credentials(){
                let data = {
                    email: this.email,
                    password: this.password,
                };

                if (this.remember){
                    data.remember = true;
                }

                return data;
            },
        },
        created() {
            this.emailPlaceholder= this.$_p_getRandomEmailPlaceholder();
        },
        mixins: [getRandomEmailPlaceholderMixin, formHelperMixin],
        name: "LoginForm"
    };
</script>

<style scoped>

</style>