import {
    SET_USER, SET_USER_ATTRIBUTE,
    SET_USER_SUBSCRIPTION,
    DECREMENT_VACANCIES_LEFT_LIMIT,
} from './mutation-types';

export default {
    /**
    *
    * @param { object } state
    * @param { object } user
    */
    [SET_USER](state, user) {
        state.user = user;
    },
    [SET_USER_SUBSCRIPTION](state, subscription){
        state.user.subscription = subscription;
    },
    [SET_USER_ATTRIBUTE](state, payload){
        state.user[payload.attributeName] = payload.attributeValue;
    },
    [DECREMENT_VACANCIES_LEFT_LIMIT](state){
        if (state.user.organizer_profile && state.user.organizer_profile.vacancies_left){
            state.user.organizer_profile.vacancies_left--;
        }
    }
};
