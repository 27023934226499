import {
    CREATE_PRIVATE_DIRECT_CHAT,
    GET_CONVERSATION_MESSAGES, MARK_CONVERSATION_AS_READ,
    SEND_PRIVAT_MESSAGE
} from "@/store/modules/chat/action-types";
import axios from '@/plugins/axios';

/**
*
* @param { function } commit
* @param { string } data
*/
export default {
    [CREATE_PRIVATE_DIRECT_CHAT]({commit} = commit, {participants, artistic_application_id}){
        return axios.post(`conversations`, {participants, artistic_application_id});
    },
    [SEND_PRIVAT_MESSAGE]({ commit } = commit, { conversationId, form }) {
        return form.post(`conversations/${conversationId}/messages`);
    },
    [GET_CONVERSATION_MESSAGES]({commit} = commit, {conversation_id, participant_id, participant_type, page = 1}){
        return axios.get(`conversations/${conversation_id}/messages`, {
            params: {participant_id, participant_type, page}
        });
    },
    [MARK_CONVERSATION_AS_READ]({commit} = commit, {conversationId, participant_id, participant_type}){
        return axios.post(`conversations/${conversationId}/read-all`, {participant_type, participant_id});
    }
};

