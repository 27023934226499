const LOGIN = 'login',
    LOGOUT = 'logout',
    REGISTER = 'register',
    AUTH = 'auth',
    UPDATE_USER = 'updateUser',
    VERIFY_EMAIL = 'verifyEmail',
    RESEND_VERIFY_EMAIL = 'resendVerifyEmail',
    SEND_RESET_PASSWORD_LINK = 'sendResetPasswordLink',
    RESET_PASSWORD = 'auth/resetPassword';

export {
    LOGIN,
    LOGOUT,
    AUTH,
    UPDATE_USER,
    REGISTER,
    VERIFY_EMAIL,
    RESEND_VERIFY_EMAIL,
    SEND_RESET_PASSWORD_LINK,
    RESET_PASSWORD,
};
