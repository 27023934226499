import {CONFIRM_INVOICE_PAYMENT, CREATE_INVOICE, GET_INVOICE} from './action-types';
import axios from "@/plugins/axios";
import {SET_USER_SUBSCRIPTION} from "@/store/modules/auth/mutation-types";

/**
*
* @param { function } commit
* @param { string } data
*/
export default {
    async [CREATE_INVOICE]({commit}, payload){
        commit;
        return axios.post(`invoices`, payload)
            .then(res => {
                return res;
            }).catch(errors => {
                throw new Error(errors);
            });
    },
    async [GET_INVOICE]({commit}){
        commit;
        return axios.get('invoices/show')
            .then(res => {
                return res;
            }).catch(errors => {
                throw new Error(errors);
            });
    },
    async [CONFIRM_INVOICE_PAYMENT]({commit}, payload){
        commit;
        return axios.post('invoices/confirm-payment', payload)
            .then(res => {
                commit('auth/' + SET_USER_SUBSCRIPTION, res.data, {root:true});
                return res;
            }).catch(errors => {
                throw new Error(errors);
            });
    }
};
