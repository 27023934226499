import Vue from 'vue';
import store from "../store";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/uk';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
dayjs.extend(relativeTime);

/**
 * Format the given date.
 */
Vue.filter('dateFromNow', value => {
    return dayjs(value).locale(store.state.base.locale).fromNow();
});

/**
 * Truncate
 */
Vue.filter('truncate', (text, length = 180, suffix = '...') => {
    if (!text || 'string' !== typeof text) return '';

    if (text.length < length) return text;

    return text.substring(0, length) + suffix;
});

/**
 * Extract youtube video id from the string
 */
Vue.filter('extractYoutubeVideoId', value => {
    var ID = '';
    if (!value) return '';

    value = value.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if(value[2] !== undefined) {
        ID = value[2].split(/[^0-9a-z_\-]/i);// eslint-disable-line
        ID = ID[0];
    }
    else {
        ID = value;
    }
    return ID;

});
/**
 * Extract youtube video id from the string and return embed link
 */
Vue.filter('getYoutubeEmbedLink', value => {
    var ID = '';
    if (!value) return '';

    value = value.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if(value[2] !== undefined) {
        ID = value[2].split(/[^0-9a-z_\-]/i);// eslint-disable-line
        ID = ID[0];
    }
    else {
        ID = value;
    }

    return ID ? `https://www.youtube.com/embed/${ID}?autoplay=0&showinfo=0&controls=0&modestbranding=1` : null;

});
/**
 * Extract youtube video id from the string and return embed link
 */
Vue.filter('capitalize', value => {
    return value.charAt(0).toUpperCase() + value.slice(1);
});