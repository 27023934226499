<template>
    <div class="home-page">
      <div class="section">
        <div class="container">
          <div class="is-max-w-lg mx-auto has-text-centered mb-3">
            <h1 class="is-size-1-desktop is-size-3 has-text-weight-bold is-spaced">
              <template v-if="$root.$i18n.locale === 'en'">
                <span>Performer is a </span>
                <span class="has-text-primary">direct communication </span>
                <span>between artists and organizers.</span>
<!--                  Without intermediaries-->
              </template>
              <template v-else-if="$root.$i18n.locale === 'ru'">
                <span>Performer - это </span>
                <span class="has-text-primary">прямое общение </span>
                <span>между артистами и организаторами.</span>
<!--                Без посредников-->
              </template>
              <template v-else-if="$root.$i18n.locale === 'uk'">
                <span>Performer - це </span>
                <span class="has-text-primary">безпосереднє спілкування </span>
                <span>між артистами та організаторами.</span>
<!--                Без посередників-->
              </template>
            </h1>
<!--            <p class="subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus eget justo et iaculis.</p>-->
            <div class="buttons is-centered mt-6">
              <router-link v-if="!isAuthenticated" class="button is-primary" to="/register">{{$t('Start free')}}</router-link>
<!--              <a class="button is-dark is-outlined" href="#">Documentation</a>-->
            </div>
          </div>
        </div>
      </div>
    <section class="section has-text-centered">
        <div class="container">
            <div>
                <h2 class="title is-3 is-spaced">{{$t('How it works')}}</h2>
                <p class="subtitle">
                    {{ $t('Performer helps to improve the effectiveness of business communication, eliminate misunderstandings and organize contacts')}}
                </p>
            </div>
        </div>
    </section>
      <section class="section">
        <div class="container">
          <div class="columns is-multiline is-centered is-centered">
            <div class="mb-6 column is-12 is-5-fullhd has-text-centered is-hidden-mobile">
                <div class="py-3" style="position: relative;">
                    <img class="is-relative is-radius is-fullwidth is-object-cover is-radius" style="height: auto; max-height: 600px; z-index: 10;" src="img/performer-land-1.jpeg" alt="">
                    <img class="is-hidden-mobile is-absolute is-top is-left" src="metis-assets/elements/blob-tear.svg" alt="">
                    <img class="is-hidden-mobile is-absolute is-bottom is-right" src="metis-assets/elements/blob-tear.svg" alt="">
                </div>
            </div>
            <div class="column is-12 is-6-fullhd is-offset-1-fullhd">
              <ul>
                <li class="mb-6 is-flex">
                  <div class="pr-5">
                    <span class="is-size-6 tag has-text-weight-bold is-rounded">1</span>
                  </div>
                  <div class="mt-1">
                    <h3 class="title is-size-5 is-spaced mb-3">{{ $t('For artists') }}</h3>
                    <p class="subtitle">
                      {{ $t('Performer is the best place to find a job. Register, create your projects and find work in minutes and without intermediaries!')}}
                    </p>
                  </div>
                </li>
                <li class="mb-6 is-flex">
                  <div class="pr-5">
                    <span class="is-size-6 tag has-text-weight-bold is-rounded">2</span>
                  </div>
                  <div class="mt-1">
                    <h3 class="title is-size-5 is-spaced mb-3">{{$t('For event agencies and art directors')}}</h3>
                    <p class="subtitle">
                      {{$t('Performer is a service of targeted delivery of your vacancies to artists. You create a vacancy - we make sure that exactly those artists who are most suitable for it see it.')}}
                    </p>
                  </div>
                </li>
                <li class="is-flex">
                  <div class="pr-5">
                    <span class="is-size-6 tag has-text-weight-bold is-rounded">3</span>
                  </div>
                  <div class="mt-1">
                    <h3 class="title is-size-5 is-spaced mb-3">{{$t('Organization and productivity')}}</h3>
                    <p class="subtitle">{{$t('The service allows you to easily organize your work schedule and keep your unique database of business contacts in perfect order')}}</p>
<!--                    <p class="subtitle">Сервис позволяет с легкостью организовать рабочий график и содержать свою уникальную базу деловых контактов в полном порядке</p>-->
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <div class="columns py-3 is-centered is-vcentered">
            <div class="column is-12">
              <div class="mb-6">
                <h2 class="mt-4 title is-size-3">{{$t('Main functionality')}}</h2>
              </div>
              <div class="is-flex mb-6">
                <div class="mr-4 has-text-primary">
                    <b-icon icon="search" size="is-medium"></b-icon>
                </div>
                <div>
                  <h3 class="title is-size-5 is-spaced mb-3">{{$t('Intelligent monitoring')}}</h3>
                  <p class="subtitle">
                    {{$t('With Performer, you no longer need to waste time tracking vacancies or finding new artists, these processes are automated!')}}
                  </p>
                  <p class="subtitle">
                    {{$t('Receive up-to-date notifications about new vacancies or projects you are interested in')}}
                  </p>

                </div>
              </div>
              <div class="is-flex mb-6">
                <div class="mr-4 has-text-primary">
                    <b-icon icon="calendar-alt" size="is-medium"></b-icon>
                </div>
                <div>
                  <h3 class="title is-size-5 is-spaced mb-3">{{$t('Event schedules')}}</h3>
                  <p class="subtitle">{{$t("Use the event planning system to create a schedule of artists performances for any period")}}</p>
                </div>
              </div>
<!--              <div class="is-flex">-->
<!--                <div class="mr-4 has-text-primary">-->
<!--                  <svg class="image is-32x32" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"></path>-->
<!--                  </svg>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <h3 class="title is-size-5 is-spaced mb-3">Best access for all of the resources</h3>-->
<!--                  <p class="subtitle">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis expedita animi.</p>-->
<!--                </div>-->
<!--              </div>-->
            </div>
<!--            <div class="column column is-12 is-5-fullhd has-text-centered is-hidden-mobile">-->
<!--              <div class="p-6" style="position: relative;"><img class="is-relative is-radius is-fullwidth is-object-cover is-radius" style="height: auto; max-height: 600px; z-index: 10;" src="img/performer-land-2.jpeg" alt=""><img class="is-hidden-mobile is-absolute is-top is-left" src="metis-assets/elements/blob-tear.svg" alt=""><img class="is-hidden-mobile is-absolute is-bottom is-right" src="metis-assets/elements/blob-tear.svg" alt=""></div>-->
<!--            </div>-->
          </div>
        </div>
      </section>
                            <!--        <section class="hero is-primary is-bold is-fullheight-with-navbar">-->
<!--            <div class="hero-body">-->
<!--                <div class="container">-->
<!--                    <h1 class="title is-1">-->
<!--                        Для артистов-->
<!--                    </h1>-->
<!--                    <h3 class="subtitle is-3">-->
<!--                        Performer - это лучшее место для поиска работы. Регестрируйтесь, создавайте свои проекты и-->
<!--                        находите работу за считанные минуты!-->
<!--                    </h3>-->
<!--                </div>-->
<!--            </div>-->
<!--        </section>-->
<!--        <section class="hero is-light is-bold is-fullheight-with-navbar">-->
<!--            <div class="hero-body">-->
<!--                <div class="container">-->
<!--                    <h1 class="title is-1">-->
<!--                        Для ивент-агенств и арт-директоров-->
<!--                    </h1>-->
<!--                    <h3 class="subtitle is-3">-->
<!--                        Performer - это сервис адресной доставки Ваших вакансий артистам. Вы создаете вакансию - мы заботимся-->
<!--                        о том, чтобы ее увидели именно те артисты, которые максимально для нее подходят-->
<!--                    </h3>-->
<!--                </div>-->
<!--            </div>-->
<!--        </section>-->
<!--        <section class="hero is-info is-bold is-fullheight-with-navbar">-->
<!--            <div class="hero-body">-->
<!--                <div class="container">-->
<!--                    <h1 class="title is-1">-->
<!--                        Как это работает?-->
<!--                    </h1>-->
<!--                    <h3 class="subtitle is-3">-->
<!--                        Performer-->
<!--                    </h3>-->
<!--                </div>-->
<!--            </div>-->
<!--        </section>-->
        <section class="hero is-bold" v-if="!isAuthenticated">
            <div class="hero-body px-0">
                <div class="container">
                    <auth-form />
                </div>
            </div>
        </section>
    </div>
</template>

<script>

    import AuthForm from "../components/Auth/AuthForm";
    import {mapGetters} from "vuex";
    export default {
        name: 'home',
        title(){
            return this.$t('Performer.com.ua');
        },
        data(){
            return {
                carousels: [
                    {
                        title: 'hello!',
                        color: 'default'
                    },
                ],
            };
        },
        components: {
            AuthForm,
        },
        created() {
            this.$Progress.finish();
        },
        computed:{
            ...mapGetters('auth',[
                'isAuthenticated',
            ]),
        }
    };
</script>

<style scoped lang="scss">
    .home-page{
        .section{
            padding: 3rem 0;
        }
    }
</style>
