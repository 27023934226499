<template>
    <b-field :label="title" :type="{'is-danger': hasError}" :message="hasError ? errorMessage : ''">
        <b-autocomplete
            v-model="cityName"
            :data="filteredCities"
            icon="search-location"
            field="name"
            :open-on-focus="true"
            @typing="emitClearError"
            :placeholder="$t('type city name')"
            autocomplete="off"
            clearable
            @select="handleCitySelect">
            <template slot="empty">{{$t('Such city does not exist')}}</template>
        </b-autocomplete>
    </b-field>
</template>

<script>
    import {GET_CITIES} from "@/store/modules/cities/action-types";
    import {mapActions} from "vuex";

    export default {
        props: {
            title: {
                type: String,
                default: function () {
                  return this.$t('Select city');
                } ,
            },
            hasError: {
                type: Boolean,
                default: false,
            },
            errorMessage: {
                type: String,
                default: '',
            },
            preselectedCityId: {
                type: Number,
                default: null,
            },
        },
        data(){
            return {
                cityName: '',
                selectedCity: null,
            };
        },
        methods: {
            ...mapActions('cities', [
                GET_CITIES
            ]),
            handleCitySelect(city){
                this.selectedCity = city;
                this.$emit('selected', this.selectedCity);
            },
            emitClearError(){
                this.$emit('clear-error');
            },
            setPreselectedCity(){
                if (this.preselectedCityId){
                    this.selectedCity = this.cities.find(city => city.id === this.preselectedCityId);
                    this.cityName = this.selectedCity.name;
                }
            },
            async fetchCities(){
                await this[GET_CITIES]();
            },
        },
        computed: {
            cities(){
                return this.$store.state.cities.cities ? this.$store.state.cities.cities : [];
            },
            filteredCities(){
                return this.cities.filter(city => {
                    return city.names_string.toString()
                        .toLowerCase()
                        .indexOf(this.cityName.toLowerCase()) >= 0;
                });
            },
            locale(){
                return this.$store.state.base.locale;
            }
        },
        async created() {
            if (0 === this.cities.length){
                await this.fetchCities();
            }

            this.setPreselectedCity();

        },
        name: "CitiesList",
        watch: {
            locale(to, from){
                if (from && to !== from){
                    this.fetchCities();
                }
            }
        },
    };
</script>

<style scoped>

</style>