import Vue from 'vue';
import Vuex from 'vuex';

/* import modules*/
import artisticVacancies from './modules/artisticVacancies/index';
import artisticProfiles from "./modules/artisticProfiles/index";
import artisticApplications from "./modules/artisticApplications/index";
import subscriptions from './modules/subscriptions/index';
import auth from "./modules/auth/index";
import relevantVacancies from './modules/relevantVacancies/index';
import base from './modules/base/index';
import artisticProfileTypes from './modules/artisticProfileTypes/index';
import instruments from './modules/instruments/index';
import artisticProfileFeatures from './modules/artisticProfileFeatures/index';
import stylisticOptionTypes from './modules/stylisticOptionTypes/index';
import cities from './modules/cities/index';
import invoices from './modules/invoices/index';
import chat from './modules/chat/index';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    modules: {
        artisticVacancies,
        artisticProfiles,
        artisticApplications,
        subscriptions,
        cities,
        auth,
        relevantVacancies,
        base,
        artisticProfileTypes,
        instruments,
        stylisticOptionTypes,
        invoices,
        chat,
        artisticProfileFeatures
    },
    state: {
    },
    mutations: {
    },
    actions: {

    },
    getters: {

    },
});