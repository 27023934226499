const SET_ARTISTIC_PROFILES  = 'setArtisticProfiles',
    SET_EDITED_ARTISTIC_PROFILE = 'setEditedArtisticProfile',
    ADD_ARTISTIC_PROFILE = 'addArtisticProfile',
    DELETE_ARTISTIC_PROFILE_FROM_LIST = 'deleteArtisticProfileFromList',
    ADD_ARTISTIC_PROFILE_LINK = 'addArtisticProfileLink',
    DELETE_ARTISTIC_PROFILE_LINK = 'deleteArtisticProfileLink',
    ADD_ARTISTIC_PROFILE_IMAGE = 'addArtisticProfileImage',
    ADD_ARTISTIC_PROFILE_FILES = 'addArtististicProfileFiles',
    DELETE_ARTISTIC_PROFILE_FILE_BY_INDEX = 'deleteArtisticProfileFileByIndex',
    ADD_ARTISTIC_PROFILE_FEE_OPTION = 'addArtisticProfileFeeOption',
    SET_EDITABLE_ARTISTIC_PROFILE_FEE_OPTIONS = 'setEditableArtisticProfileFeeOptions',
    DELETE_ARTISTIC_PROFILE_FEE_OPTION = 'deleteArtisticProfileFeeOption',
    UPDATE_ARTISTIC_PROFILE_IN_LIST = 'updateArtisticProfileInList',
    SET_ARTISTIC_PROFILES_COUNT = 'setArtisticProfilesCount',
    DECREMENT_ARTISTIC_PROFILE_LIMIT = 'decrementArtisticProfileLimit',
    DELETE_ARTISTIC_PROFILE_IMAGE_BY_UUID = 'deleteArtisticProfileImageByUuid',
    SET_FAVORITE_ARTISTIC_PROFILES = 'setFavoriteArtisticProfiles',
    REMOVE_PROFILE_FROM_FAVORITE_COLLECTION = 'removeProfileFromFavoriteCollection';

export {
    SET_ARTISTIC_PROFILES,
    SET_EDITED_ARTISTIC_PROFILE,
    ADD_ARTISTIC_PROFILE,
    ADD_ARTISTIC_PROFILE_LINK,
    DELETE_ARTISTIC_PROFILE_LINK,
    ADD_ARTISTIC_PROFILE_IMAGE,
    ADD_ARTISTIC_PROFILE_FILES,
    DELETE_ARTISTIC_PROFILE_FILE_BY_INDEX,
    ADD_ARTISTIC_PROFILE_FEE_OPTION,
    DELETE_ARTISTIC_PROFILE_FEE_OPTION,
    SET_EDITABLE_ARTISTIC_PROFILE_FEE_OPTIONS,
    UPDATE_ARTISTIC_PROFILE_IN_LIST,
    DELETE_ARTISTIC_PROFILE_FROM_LIST,
    SET_ARTISTIC_PROFILES_COUNT,
    DECREMENT_ARTISTIC_PROFILE_LIMIT,
    DELETE_ARTISTIC_PROFILE_IMAGE_BY_UUID,
    SET_FAVORITE_ARTISTIC_PROFILES,
    REMOVE_PROFILE_FROM_FAVORITE_COLLECTION,
};
