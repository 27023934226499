const GET_ARTISTIC_VACANCIES  = 'getArtisticVacancies',
    GET_ARTISTIC_VACANCIES_COUNT = 'getArtisticVacanciesCount',
    CREATE_BLANK_ARTISTIC_VACANCY = 'createBlankArtisticVacancy',
    UPDATE_ARTISTIC_VACANCY = 'updateArtisticVacancy',
    DESTROY_ARTISTIC_VACANCY = 'destroyArtisticVacancy',
    CLOSE_VACANCY = 'closeVacancy',
    GET_ARTISTIC_VACANCY = 'getArtisticVacancy';

export {
    GET_ARTISTIC_VACANCIES,
    GET_ARTISTIC_VACANCIES_COUNT,
    CREATE_BLANK_ARTISTIC_VACANCY,
    UPDATE_ARTISTIC_VACANCY,
    DESTROY_ARTISTIC_VACANCY,
    CLOSE_VACANCY,
    GET_ARTISTIC_VACANCY,
};
