<template>
  <div class="" id="app">

    <section class="section pt-0 px-2 is-background-no-repeat main-content">
      <div class="columns" style="min-height: 600px">
        <div class="column is-half is-offset-one-quarter">
          <nav-bar />

          <router-view class="main-content"></router-view>

          <vue-progress-bar ></vue-progress-bar>
        </div>
      </div>

    </section>

    <footer class="footer">
        <div class="content has-text-centered columns is-align-items-center ">
          <div class="column is-6-tablet">
              <strong>Performer</strong> by <strong>Kutas&Co</strong>
              <br>
              © 2020-{{currentYear}} | <span id="version">v2022.05.08</span>-beta
          </div>
          <div class="column is-6-tablet">
            <router-link class="button is-text" :to="{name: 'reports'}">{{$t('Сomplaints & Suggestions')}}</router-link>
          </div>
        </div>

    </footer>
  </div>
</template>

<script>
    import NavBar from '@/components/TheNavBar';
    import {mapActions, mapState} from "vuex";
    import {GET_APPLICATION_TYPES, SKIP_WORKER_WAITING} from "@/store/modules/base/action-types";
    export default {
        components: { NavBar},
        computed: {
            ...mapState('base', [
                'updatesAvailable',
                'workerRegistration',
            ]),
            shouldWrappedInBox(){
                return ! ['login', 'register', 'passwordReset', 'home'].includes(this.$route.name);
            },
            currentYear(){
                return new Date().getFullYear();
            },
        },
        methods: {
            ...mapActions('base', [
                SKIP_WORKER_WAITING,
                GET_APPLICATION_TYPES,
            ]),
            updatePage(){
                this[SKIP_WORKER_WAITING]();
            },
        },
        created() {
            if (!this.$store.state.base.applicationTypes ){
                this[GET_APPLICATION_TYPES]();
            }
        }
    };

</script>

<style lang="scss">
    // Import Bulma's core
    @import "~bulma/sass/utilities/_all";

    // Import Bulma and Buefy styles
    @import "~bulma";
    @import "~buefy/src/scss/buefy";


    #app {
        display: flex;
        min-height: 100vh;
        flex-direction: column;
        .main-content {
            flex: 1;
        }
      .footer {
        padding: 3rem 1.5rem 3rem;
      }
    }
</style>
