import {
    GET_ARTISTIC_VACANCIES_COUNT,
    GET_ARTISTIC_VACANCIES,
    CREATE_BLANK_ARTISTIC_VACANCY,
    UPDATE_ARTISTIC_VACANCY,
    DESTROY_ARTISTIC_VACANCY,
    CLOSE_VACANCY, GET_ARTISTIC_VACANCY
} from './actions-types';
import axios from '@/plugins/axios';
import {
    SET_ARTISTIC_VACANCIES,
    SET_ARTISTIC_VACANCIES_COUNT,
    SET_EDITED_ARTISTIC_VACANCY,
    UPDATE_ARTISTIC_VACANCY_IN_LIST,
    DELETE_ARTISTIC_VACANCY_FROM_LIST,
} from "./mutation-types";
import {DECREMENT_VACANCIES_LEFT_LIMIT} from "@/store/modules/auth/mutation-types";
/**
*
* @param { function } commit
* @param { string } data
*/
export default {
    async [GET_ARTISTIC_VACANCIES]({commit}, {page}){
        return axios.get('vacancies', {params: {page}})
            .then(res => {
                commit(SET_ARTISTIC_VACANCIES, res.data.data);
                return res;
            });
    },
    async [GET_ARTISTIC_VACANCIES_COUNT]({commit}){
        return axios.get('vacancies/count')
            .then(res => {
                commit(SET_ARTISTIC_VACANCIES_COUNT, res.data);
                return res;
            });
    },
    async [CREATE_BLANK_ARTISTIC_VACANCY]({commit}){
        return axios.post('vacancies')
            .then(res => {
                commit(SET_EDITED_ARTISTIC_VACANCY, res.data);
                return res;
            });
    },
    async [UPDATE_ARTISTIC_VACANCY]({commit}, vacancy){
        return axios.put(`vacancies/${vacancy.id}`, vacancy)
            .then(res => {
                commit(SET_EDITED_ARTISTIC_VACANCY, res.data);
                commit(UPDATE_ARTISTIC_VACANCY_IN_LIST  , res.data);
                if (res.data.published){
                    commit('auth/' + DECREMENT_VACANCIES_LEFT_LIMIT, null, {root: true});
                }
                return res;
            });
    },
    [DESTROY_ARTISTIC_VACANCY]({commit = commit}, vacancyId){
        return axios.delete(`vacancies/${vacancyId}`);
    },
    async [CLOSE_VACANCY]({commit}, vacancyId){
        return axios.put(`vacancies/${vacancyId}/close`, {active: false})
            .then(res => {
                commit(DELETE_ARTISTIC_VACANCY_FROM_LIST, vacancyId);
                return res;
            });
    },
    async [GET_ARTISTIC_VACANCY]({commit}, vacancyId){
        commit;
        return axios.get(`vacancies/${vacancyId}`)
            .then(res => {
                return res;
            });
    }
};
