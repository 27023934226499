import {
    GET_ARTISTIC_PROFILES,
    CREATE_BLANK_ARTISTIC_PROFILE,
    DESTROY_ARTISTIC_PROFILE,
    UPDATE_ARTISTIC_PROFILE,
    GET_ARTISTIC_PROFILES_COUNT,
    GET_ARTISTIC_PROFILE,
    TOGGLE_IN_FAVORITE_COLLECTION,
    GET_FAVORITE_ARTISTIC_PROFILES_COLLECTION,
    REMOVE_ARTISTIC_PROFILE_FROM_FAVORITE_COLLECTION,
} from './actions-types';
import {
    SET_ARTISTIC_PROFILES,
    SET_EDITED_ARTISTIC_PROFILE,
    UPDATE_ARTISTIC_PROFILE_IN_LIST,
    SET_ARTISTIC_PROFILES_COUNT,
    SET_FAVORITE_ARTISTIC_PROFILES,
} from "./mutation-types";
import axios from '@/plugins/axios';

export default {
    async [GET_ARTISTIC_PROFILES]({commit}, {page}){
        return axios.get('artistic-profiles', {params: {page}})
            .then(res => {
                commit(SET_ARTISTIC_PROFILES, res.data.data);
                return res;
            });
    },
    async [CREATE_BLANK_ARTISTIC_PROFILE]({commit}){
            return axios.post('artistic-profiles')
                .then(res => {
                    commit(SET_EDITED_ARTISTIC_PROFILE, res.data);
                    return res;
                });
    },
    [DESTROY_ARTISTIC_PROFILE]({commit = commit}, profileId){
        return axios.delete(`artistic-profiles/${profileId}`);
    },
    async [UPDATE_ARTISTIC_PROFILE]({commit}, payload){
        return axios.put(`artistic-profiles/${payload.artisticProfileId}`, payload.data)
            .then(res => {
                commit(SET_EDITED_ARTISTIC_PROFILE, res.data);
                commit(UPDATE_ARTISTIC_PROFILE_IN_LIST, res.data);
                return res;
            });
    },
    async [GET_ARTISTIC_PROFILES_COUNT]({commit}){
        return axios.get('artistic-profiles/count')
            .then(res => {
                commit(SET_ARTISTIC_PROFILES_COUNT, res.data);
                return res;
            });
    },
    async [GET_ARTISTIC_PROFILE]({commit}, artisticProfileId){
        commit;
        return axios.get(`artistic-profiles/${artisticProfileId}`)
            .then(res => {
                return res;
            });
    },
    async [TOGGLE_IN_FAVORITE_COLLECTION]({commit}, artisticProfileId){
        commit;
        return axios.post(`collection/artistic-profile/${artisticProfileId}/toggle`)
            .then(res => {
                return res;
            });
    },
    async [GET_FAVORITE_ARTISTIC_PROFILES_COLLECTION]({commit}, {params}){
        return axios.get('collection/artistic-profiles', {params: params})
            .then(res => {
                commit(SET_FAVORITE_ARTISTIC_PROFILES, res.data.data);
                return res;
            });
    },
    [REMOVE_ARTISTIC_PROFILE_FROM_FAVORITE_COLLECTION]({commit} = commit, artisticProfileId){
        return axios.delete(`collection/artistic-profile/${artisticProfileId}`);
    }
};
