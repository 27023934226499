import {ACTIVE_ARTISTIC_PROFILE_EDITING, ACTIVE_ARTISTIC_PROFILE_CREATION} from "./getters-types";


export default {
    [ACTIVE_ARTISTIC_PROFILE_EDITING](state){
        return !! state.editedProfile;
    },
    [ACTIVE_ARTISTIC_PROFILE_CREATION](state){

        return state.editedProfile ? undefined === state.editedProfile.id : false;

    }
};
