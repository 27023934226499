const GET_DATA_FOR_CREATION = 'getDataForCreation',
    SKIP_WORKER_WAITING = 'skipWorkerWaiting',
    GET_APPLICATION_TYPES = 'getApplicationTypes',
    SET_APPLICATION_LOCALE = 'setApplicationLocale';

export {
    GET_DATA_FOR_CREATION,
    SKIP_WORKER_WAITING,
    GET_APPLICATION_TYPES,
    SET_APPLICATION_LOCALE,
};
