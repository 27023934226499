const GET_ARTISTIC_PROFILES = 'getArtisticProfiles',
    CREATE_BLANK_ARTISTIC_PROFILE = 'createBlankArtisticProfile',
    DESTROY_ARTISTIC_PROFILE = 'destroyArtisticProfile',
    UPDATE_ARTISTIC_PROFILE = 'updateArtisticProfile',
    GET_ARTISTIC_PROFILES_COUNT = 'getArtisticProfilesCount',
    GET_ARTISTIC_PROFILE = 'getArtisticProfile',
    TOGGLE_IN_FAVORITE_COLLECTION = 'toggleInFavoriteCollection',
    GET_FAVORITE_ARTISTIC_PROFILES_COLLECTION = 'getFavoriteArtisticProfilesCollection',
    REMOVE_ARTISTIC_PROFILE_FROM_FAVORITE_COLLECTION = 'removeArtisticProfileFromFavoriteCollection';

export {
    GET_ARTISTIC_PROFILES,
    CREATE_BLANK_ARTISTIC_PROFILE,
    DESTROY_ARTISTIC_PROFILE,
    UPDATE_ARTISTIC_PROFILE,
    GET_ARTISTIC_PROFILES_COUNT,
    GET_ARTISTIC_PROFILE,
    TOGGLE_IN_FAVORITE_COLLECTION,
    GET_FAVORITE_ARTISTIC_PROFILES_COLLECTION,
    REMOVE_ARTISTIC_PROFILE_FROM_FAVORITE_COLLECTION,
};
