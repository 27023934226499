const SEND_PRIVAT_MESSAGE = 'sendPrivateMessage',
    CREATE_PRIVATE_DIRECT_CHAT = 'createPrivatApplicationChat',
    GET_CONVERSATION_MESSAGES = 'getConversationMessages',
    MARK_CONVERSATION_AS_READ = 'markConversationAsRead';

export {
    CREATE_PRIVATE_DIRECT_CHAT,
    SEND_PRIVAT_MESSAGE,
    GET_CONVERSATION_MESSAGES,
    MARK_CONVERSATION_AS_READ,
};