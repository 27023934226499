const SET_ARTISTIC_APPLICATIONS = 'setArtisticApplications';
const SET_ORGANIZER_ARTISTIC_APPLICATIONS = 'setOrganizerArtisticApplications';
const ADD_ARTISTIC_APPLICATION = 'addArtisticApplication';
const REMOVE_ARTISTIC_APPLICATION_FROM_LIST = 'removeArtisticApplicationFromList';
const UPDATE_ARTISTIC_APPLICATION_IN_LIST = 'updateArtisticApplicationInList';
const UPDATE_ARTISTIC_APPLICATION_STATUS_IN_LIST = 'updateArtisticApplicationStatusInList';
const UPDATE_ORGANIZER_ARTISTIC_APPLICATION_STATUS_IN_LIST = 'updateOrganizerArtisticApplicationStatusInList';
const REMOVE_ORGANIZER_ARTISTIC_APPLICATION_FROM_LIST = 'removeOrganizerArtisticApplicationFromList';
const SET_FILTER_FOR_VACANCY = 'setFilterForVacancy';
const SET_FILTER_STATUS = 'setFilterStatus';
const TOGGLE_ORGANIZER_APPLICATION_PROFILE_IN_COLLECTION_STATUS = 'toggleOrganizerApplicationProfileInCollectionStatus';
const SET_ARTISTIC_APPLICATION_ATTRIBUTE = 'setArtisticApplicationAttribute';

export {
    SET_ARTISTIC_APPLICATIONS,
    SET_ORGANIZER_ARTISTIC_APPLICATIONS,
    REMOVE_ARTISTIC_APPLICATION_FROM_LIST,
    ADD_ARTISTIC_APPLICATION,
    UPDATE_ARTISTIC_APPLICATION_IN_LIST,
    UPDATE_ARTISTIC_APPLICATION_STATUS_IN_LIST,
    SET_FILTER_FOR_VACANCY,
    SET_FILTER_STATUS,
    REMOVE_ORGANIZER_ARTISTIC_APPLICATION_FROM_LIST,
    UPDATE_ORGANIZER_ARTISTIC_APPLICATION_STATUS_IN_LIST,
    TOGGLE_ORGANIZER_APPLICATION_PROFILE_IN_COLLECTION_STATUS,
    SET_ARTISTIC_APPLICATION_ATTRIBUTE,
};