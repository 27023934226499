export default {
"80_90s": "80-90s",
"Accept the application?": "Accept the application?",
"Add a comment to your application": "Add a comment to your application",
"Additional promo links": "Additional promo links",
"Application has been accepted by you": "The application has been accepted by you",
"Application sent": "Application sent",
"Applications": "Applications",
"Artist": "Artist",
"Can't reset password": "Can't reset password",
"Cancel": "Cancel",
"Change password": "Change password",
"Changes applied": "Changes applied",
"Changes saved": "Changes saved",
"Click to select": "Click to select",
"Close the vacancy?": "Close the vacancy?",
"Confirm password": "Confirm password",
"Create vacancy": "Create vacancy",
"Current password": "Current password",
"Date": "Date",
"Description": "Description",
"Did not get the email": "Did not get the email",
"Display vacancies for": "Display vacancies for",
"Email": "Email",
"Email confirmed": "Email confirmed",
"Event schedules": "Event schedules",
"Example links": "Example links",
"Favorite projects": "Favorite projects",
"Fee": "Fee",
"Field is required": "Field is required",
"For artists": "For artists",
"For event agencies and art directors": "For event agencies and art directors",
"Forget password?": "Forget password?",
"Gallery": "Gallery",
"Go home": "Go home",
"Go to applications list?": "Go to the applications list?",
"Go to relevant vacancies?": "Go to relevant vacancies?",
"Go to the plans!": "Go to the plans!",
"Hide the application?": "Hide the application?",
"Home": "Home",
"How it works": "How it works",
"I'm organizer": "I'm organizer",
"Intelligent monitoring": "Intelligent monitoring",
"Link": "Link",
"Link text": "Link text",
"Location": "Location",
"Login": "Login",
"Logout": "Logout",
"Main functionality": "Main functionality",
"Message": "Message",
"Name": "Name",
"New password": "New password",
"No": "No",
"No messages": "No messages",
"No projects have been added to the collection": "No projects have been added to the collection",
"Nothing here yet": "Nothing here yet...",
"Organization and productivity": "Organization and productivity",
"Organizer": "Organizer",
"Organizer profile": "Organizer profile",
"Password": "Password",
"Password successful changed": "Password successful changed",
"Password updated": "Password updated",
"Performer helps to improve the effectiveness of business communication, eliminate misunderstandings and organize contacts": "Performer helps to improve the effectiveness of business communication, eliminate misunderstandings and organize contacts.",
"Performer is a service of targeted delivery of your vacancies to artists. You create a vacancy - we make sure that exactly those artists who are most suitable for it see it.": "Performer is a service of targeted delivery of your vacancies to artists. You create a vacancy - we make sure that exactly those artists who are most suitable for it see it.",
"Performer is the best place to find a job. Register, create your projects and find work in minutes and without intermediaries!": "Performer is the best place to find a job. Register, create your projects and find work in minutes and without intermediaries!",
"Please confirm": "Please confirm",
"Please fill out the Organizer's profile to be able to create vacancies": "Please fill out the Organizer's profile to be able to create vacancies",
"Please, check form fields": "Please, check form fields",
"Please, wait a minute": "Please, wait a minute...",
"Position": "Position",
"Profile name": "Profile name",
"Project was updated": "Project was updated",
"Project without name": "Project without name",
"Publish": "Publish",
"Ready for abroad contracts": "Ready for abroad contracts",
"Ready for work in other cities": "Ready for work in other cities",
"Receive up-to-date notifications about new vacancies or projects you are interested in": "Receive up-to-date notifications about new vacancies or projects you are interested in.",
"Register": "Register",
"Registration": "Registration",
"Reject the application?": "Reject the application?",
"Remember me": "Remember me",
"Remove profile from favorites?": "Remove profile from favorites?",
"Remove the organizer profile? All artists collections, conversations, vacancies will deleted": "Remove the organizer profile? All artists collections, conversations, vacancies will deleted",
"Remove unpublished project?": "Remove unpublished project?",
"Remove unpublished vacancy?": "Remove unpublished vacancy?",
"Request a proposal for a fee from a candidate": "Request a proposal for a fee from a candidate",
"Requirements": "Requirements",
"Reset": "Reset",
"Reset link has been send": "Reset link has been send",
"Reset password": "Reset password",
"Riders": "Riders",
"Save": "Save",
"Save password": "Save password",
"Select": "Select",
"Select city": "Select city",
"Select dancing styles": "Select dancing styles",
"Select instrument": "Select instrument",
"Select instruments": "Select instruments",
"Select instruments you need": "Select instruments you need",
"Select instruments you use": "Select instruments you use",
"Select musical styles": "Select musical styles",
"Select profile type": "Select profile type",
"Select show type": "Select show type",
"Select the main city of work": "Select the main city of work",
"Send": "Send",
"Send another one": "Send another one",
"Send application": "Send application",
"Set new password": "Set new password",
"Show applications for": "Show applications for",
"Show type": "Show type",
"Start free": "Start free",
"Subscribe": "Subscribe",
"Subscription": "Subscription",
"Subscription limit reached": "Subscription limit reached",
"Such city does not exist": "Such city does not exist",
"Thank you for registration": "Thank you for registration",
"Thank you!": "Thank you!",
"The project was not published": "The project was not published",
"The service allows you to easily organize your work schedule and keep your unique database of business contacts in perfect order": "The service allows you to easily organize your work schedule and keep your unique database of business contacts in perfect order",
"The vacancy was not published": "The vacancy was not published",
"There are no rejected applications yet": "There are no rejected applications yet",
"There are no suitable applications yet, but don't worry! We will send you a notification as soon as something appears": "There are no suitable applications yet, but don't worry! We will send you a notification as soon as something appears",
"There are no suitable vacancies yet, but don't worry! We will send you a notification as soon as something appears": "There are no suitable vacancies yet, but don't worry! We will send you a notification as soon as something appears",
"Update": "Update",
"Upload images": "Upload images",
"Upload rider": "Upload rider",
"Use the event planning system to create a schedule of artists performances for any period": "Use the event planning system to create a schedule of artists' performances for any period.",
"User profile": "User profile",
"Vacancies": "Vacancies",
"Vacancy has been updated": "The vacancy has been updated",
"Verification email sent": "Verification email sent",
"We couldn't find this page": "We couldn't find this page...",
"We do not show this information to third parties, but it will help us offer you the most suitable vacancies": "We do not show this information to third parties, but it will help us offer you the most suitable vacancies",
"We have sent you an email to confirm your email address. Check your mail and follow the instructions.": "We have sent you an email to confirm your email address. Check your mail and follow the instructions.",
"We sent a password reset link to the specified mail. Check your mail and follow the instructions": "We sent a password reset link to the specified mail. Check your mail and follow the instructions",
"Who are you looking for?": "Who are you looking for?",
"With Performer, you no longer need to waste time tracking vacancies or finding new artists, these processes are automated!": "With Performer, you no longer need to waste time tracking vacancies or finding new artists, these processes are automated!",
"Workplace": "Workplace",
"Yes": "Yes",
"You can specify a lower threshold for the fee. We do not show this information to third parties, but it will help us offer you the most suitable vacancies.": "You can specify a lower threshold for the fee. We do not show this information to third parties, but it will help us offer you the most suitable vacancies.",
"You have no published vacancies": "You have no published vacancies",
"You have reached your vacancy application subscription limit": "You have reached your vacancy application subscription limit",
"You have {count} open vacancies": "You have {count} open vacancies",
"You have {count} projects": "You have {count} projects",
"You've Chosen the {plan} plan": "You've Chosen the {plan} plan",
"Your Facebook profile link": "Your Facebook profile link",
"Your Instagram profile link": "Your Instagram profile link",
"Your application": "Your application",
"Your application has been accepted": "Your application has been accepted",
"Your best promo": "Your best promo",
"Your email": "Your email",
"Your message has been sent": "Your message has been sent",
"Your name, nickname or team name": "Your name, nickname or team name",
"Your new password": "Your new password",
"Your payment offer": "Your payment offer",
"Your projects": "Your projects",
"Youtube link on your best or latest promo video": "Youtube link on your best or latest promo video",
"accept": "accept",
"accepted": "accepted",
"acoustic_drums": "acoustic drums",
"acoustic_guitar": "acoustic guitar",
"acrobatic": "acrobatic",
"add": "add",
"agreed": "agreed",
"agreed budget": "agreed budget",
"agreed fee": "agreed fee",
"all projects": "all projects",
"all vacancies": "all vacancies",
"amount": "amount",
"animator": "animator",
"application accepted": "application accepted",
"application rejected": "application rejected",
"application sent": "application sent",
"applications": "applications",
"archive": "archive",
"b_boxing": "b boxing",
"back": "back",
"ballet": "ballet",
"band": "band",
"bandura": "bandura",
"barman": "barman",
"bass_guitar": "bass guitar",
"bavarian": "bavarian",
"break_dance": "break dance",
"cajon": "cajon",
"cello": "cello",
"change": "change",
"city": "city",
"classic": "classic",
"clear": "clear",
"clear errors": "clear errors",
"close": "close",
"continue": "continue",
"country": "country",
"create new project": "create new project",
"create vacancy": "create vacancy",
"dance": "dance",
"dancer": "dancer",
"deep_house": "deep house",
"delete": "delete",
"describe the requirements in details": "describe the requirements in details",
"description": "description",
"details": "details",
"dj": "dj",
"dj_instrumental": "dj instrumental",
"double_bass": "double bass",
"draft": "draft",
"east": "east",
"edit": "edit",
"electric_drums": "electric drums",
"electric_guitar": "electric guitar",
"enter the result of the expression": "enter the result of the expression",
"examples": "examples",
"fee": "fee",
"fire": "fire",
"fixed": "fixed",
"flute": "flute",
"folk": "folk",
"for all vacancies": "for all vacancies",
"for vacancy": "for vacancy",
"freak": "freak",
"french": "french",
"funk": "funk",
"go back": "go back",
"go-go": "go-go",
"guitar": "guitar",
"gymnast": "gymnast",
"hang": "hang",
"hide": "hide",
"hide details": "hide details",
"host": "host",
"ice": "ice",
"illusionist": "illusionist",
"indicate a place of work": "indicate a place of work",
"indie_rock": "indie rock",
"instruments": "instruments",
"is abroad contract": "is abroad contract",
"italian": "italian",
"jazz": "jazz",
"keys": "keys",
"laser": "laser",
"latin": "latin",
"led": "led",
"light": "light",
"links": "links",
"lounge": "lounge",
"master_class": "master class",
"mc": "mc",
"music": "music",
"original_genre": "original genre",
"percussion": "percussion",
"pop": "pop",
"profile type": "profile type",
"punk_rock": "punk rock",
"pyrotechnic": "pyrotechnic",
"r&b": "r&b",
"reject": "reject",
"rejected": "rejected",
"remove": "remove",
"requirements": "requirements",
"resend": "resend",
"rock": "rock",
"sand_animation": "sand animation",
"sax": "sax",
"search all over Ukraine": "search all over Ukraine",
"select dancing styles you need": "select dancing styles you need",
"select musical styles you need": "выберите музыкальные стили которые вам нужны",
"send message": "send message",
"show": "show",
"show all": "show all",
"show more": "show more",
"show rejected applications": "show rejected applications",
"singer": "singer",
"solo_performer": "solo performer",
"soul": "soul",
"spd": "spd",
"stand_up": "stand up",
"strip": "strip",
"styles": "styles",
"swing": "swing",
"tap_dance": "tap dance",
"theatrical": "theatrical",
"trombone": "trombone",
"trumpet": "trumpet",
"tuba": "tuba",
"type city name": "type city name...",
"ukrainian": "ukrainian",
"ukulele": "ukulele",
"unpublished vacancy": "unpublished vacancy",
"uploading error": "uploading error",
"viola": "viola",
"violin": "violin",
"vocal": "vocal",
"waiting for downloads": "waiting for downloads",
"world_hits": "world hits",
"Сomplaints & Suggestions": "Сomplaints & Suggestions"};