import {GET_SUBSCRIPTION_PLAN, GET_SUBSCRIPTION_PLANS} from './action-types';
import axios from "@/plugins/axios";
import {SET_SUBSCRIPTION_PLANS} from "./mutation-types";

/**
*
* @param { function } commit
* @param { string } data
*/
export default {
    async [GET_SUBSCRIPTION_PLANS]({commit}) {
        return axios.get('subscription-plans')
            .then(res => {
                commit(SET_SUBSCRIPTION_PLANS, res.data);
                return res;
            }).catch(errors => {
                return errors;
            });
    },
    async [GET_SUBSCRIPTION_PLAN]({commit}, planId){
        commit;
        return axios.get(`subscription-plans/${planId}`)
            .then(res => {
                return res;
            }).catch(errors => {
                return errors;
            });
    }
};
