import Vue from 'vue';
import App from './App.vue';
import Buefy from 'buefy';
import Axios from './plugins/axios';
import VueAxios from "vue-axios";
import VueCookies from 'vue-cookies';
import VueImg from 'v-img';
import VueI18n from 'vue-i18n';
import VueLodash from "vue-lodash";
import lodash from 'lodash';
import VueGtag from "vue-gtag";
import router from './router/routes';


import buefyConfig from "../buefy.config";
import store from './store';
import messages from './i18n/index';
import VueProgressBar from 'vue-progressbar';

import { HasError, AlertError } from 'vform/src/components/bootstrap5';

if(process.env.VUE_APP_GTAG_ID){
    Vue.use(VueGtag, {
        config: { id: process.env.VUE_APP_GTAG_ID }
    }, router);
}

Vue.prototype.$gEvent = function (event, data){
    try {
        Vue.$gtag.event(event, data);
    } catch (e){
        e;
    }
};

Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);
Vue.use(Buefy, buefyConfig);
Vue.use(VueProgressBar, {
  color: '#00d1b2',
  failedColor: 'red',
  thickness: '5px',
});
Vue.use(VueAxios, Axios);
Vue.use(VueCookies);
Vue.use(VueImg);
Vue.use(VueI18n);
Vue.use(VueLodash, {lodash: lodash});
require('./filters/filters');

//swiper
import 'swiper/swiper.scss';

import VueEcho from 'vue-echo-laravel';
import Echo from "laravel-echo";
window.Pusher = require('pusher-js');

const EchoInstance = new Echo({
    broadcaster: 'pusher',
    cluster: 'mt1',
    encrypted: false,
    key: process.env.VUE_APP_PUSHER_KEY,
  wsHost: process.env.VUE_APP_PUSHER_WS_HOST,
  wsPort: process.env.VUE_APP_PUSHER_WS_PORT,
  forceTLS: process.env.VUE_APP_PUSHER_FORCE_TLS,
  disableStats: true,
    authorizer: (channel) => {
        return {
          authorize: (socketId, callback) => {
            Axios.post('/broadcasting/auth', {
              socket_id: socketId,
              channel_name: channel.name
            })
                .then(response => {
                  callback(false, response.data);
                })
                .catch(error => {
                  callback(true, error);
                });
          }
    };
  },
});

Vue.use(VueEcho, EchoInstance);

const i18n = new VueI18n({
  locale: 'ua', // set locale
  messages, // set locale messages
});

//Styles
//import 'buefy/dist/buefy.css'
import '@fortawesome/fontawesome-free/css/all.min.css';

//import './registerServiceWorker';

//temporary disable subscriptions counters
Vue.prototype.$subscriptionDisabled = true;

//global mixins
import metaTitleMixin from "@/mixins/metaTitleMixin";
Vue.mixin(metaTitleMixin);
store.dispatch('auth/auth').then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app');
});
