import axios from 'axios';

export default {
    data(){
        return {
            formBusy: false,
            errors: {},
        };
    },
    methods: {
        $_p_formPost(route, data){
            this.formBusy = true;

            return new Promise((resolve, reject) => {
                axios.post(route, data)
                    .then(res => {
                        this.formBusy = false;
                        resolve(res);
                    })
                    .catch(error => {
                        this.formBusy = false;
                        if (422 === error.response.status){
                            this.errors = error.response.data.errors;
                        } else {
                            this.$buefy.toast.open('cant make request');
                        }
                        reject(error);
                    });

            });
        },
        $_p_formPut(route, data){
            this.formBusy = true;

            return new Promise((resolve, reject) => {
                axios.put(route, data)
                    .then(res => {
                        this.formBusy = false;
                        resolve(res);
                    })
                    .catch(error => {
                        this.formBusy = false;
                        if (422 === error.response.status){
                            this.errors = error.response.data.errors;
                        } else {
                            this.$buefy.toast.open('cant make request');
                        }
                        reject(error);
                    });

            });
        },
        $_p_formDelete(route){

            this.formBusy = true;

            return new Promise((resolve, reject) => {
                axios.delete(route)
                    .then(res => {
                        this.formBusy = false;
                        resolve(res);
                    })
                    .catch(error => {
                        this.formBusy = false;
                        if (422 === error.response.status){
                            this.errors = error.response.data.errors;
                        } else {
                            this.$buefy.toast.open('can\'t make request');
                        }
                        reject(error);
                    });

            });
        },
        async $_p_formDispatch(action, data){
            this.formBusy = true;
            return this[action](data)
                .then(res => {
                    return res;
                })
                .catch(error => {
                    this.errors = error.response ?  error.response.data.errors : {};
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.formBusy = false;
                });
        },
        $_p_formPutError(errors){
            this.errors = Object.assign({}, this.errors, errors);
        },
        $_p_formGetError(name){
            return this.errors[name];
        },
        $_p_formHasError(name){
            return undefined !== this.errors[name];
        },
        $_p_formClearError(name){
            delete this.errors[name];
        },
    },

};