import {
    SET_ARTISTIC_PROFILES,
    SET_EDITED_ARTISTIC_PROFILE,
    ADD_ARTISTIC_PROFILE_LINK,
    DELETE_ARTISTIC_PROFILE_LINK,
    ADD_ARTISTIC_PROFILE_IMAGE,
    ADD_ARTISTIC_PROFILE_FILES,
    DELETE_ARTISTIC_PROFILE_FILE_BY_INDEX,
    ADD_ARTISTIC_PROFILE_FEE_OPTION,
    DELETE_ARTISTIC_PROFILE_FEE_OPTION,
    SET_EDITABLE_ARTISTIC_PROFILE_FEE_OPTIONS,
    UPDATE_ARTISTIC_PROFILE_IN_LIST,
    ADD_ARTISTIC_PROFILE,
    DELETE_ARTISTIC_PROFILE_FROM_LIST,
    SET_ARTISTIC_PROFILES_COUNT,
    DECREMENT_ARTISTIC_PROFILE_LIMIT,
    DELETE_ARTISTIC_PROFILE_IMAGE_BY_UUID, SET_FAVORITE_ARTISTIC_PROFILES, REMOVE_PROFILE_FROM_FAVORITE_COLLECTION,
} from './mutation-types';
import Vue from 'vue';


export default {

    [SET_ARTISTIC_PROFILES](state, profiles) {
        state.profiles = profiles;
    },
    [SET_EDITED_ARTISTIC_PROFILE](state, profile){
        state.editedProfile = profile;
    },
    [ADD_ARTISTIC_PROFILE](state, profile){
        state.profiles.push(profile);
    },
    [DELETE_ARTISTIC_PROFILE_FROM_LIST](state, profileId){
        state.profiles = state.profiles.filter(profile => profile.id !== profileId);
    },
    [ADD_ARTISTIC_PROFILE_LINK](state, link) {
        state.editedProfile.links.push(link);
    },
    [DELETE_ARTISTIC_PROFILE_LINK](state, payload) {
        state.editedProfile.links.splice(payload.index, 1);
    },
    [ADD_ARTISTIC_PROFILE_IMAGE](state, payload){
        if (Array.isArray(state.editedProfile.images)){
            state.editedProfile.images = {};
        }
        Vue.set(state.editedProfile.images, payload.uuid, payload);
    },
    [DELETE_ARTISTIC_PROFILE_IMAGE_BY_UUID](state, uuid){
        // state.editedProfile.images = state.editedProfile.images.filter(image => image.uuid !== uuid);
        Vue.delete(state.editedProfile.images, uuid);
    },

    [ADD_ARTISTIC_PROFILE_FILES](state, payload){
        if (Array.isArray(payload)){
            state.editedProfile.files.concat(payload);
        } else {
            state.editedProfile.files.push(payload);
        }
    },
    [DELETE_ARTISTIC_PROFILE_FILE_BY_INDEX](state, payload){
        state.editedProfile.files.splice(payload, 1);
    },
    [ADD_ARTISTIC_PROFILE_FEE_OPTION](state, feeOption){
        state.editedProfile.fee_options.push(feeOption);
    },
    [DELETE_ARTISTIC_PROFILE_FEE_OPTION](state, payload){
        state.editedProfile.fee_options.splice(payload.index, 1);
    },
    [SET_EDITABLE_ARTISTIC_PROFILE_FEE_OPTIONS](state, options){
        state.editedProfile.fee_options = options;
    },
    [UPDATE_ARTISTIC_PROFILE_IN_LIST](state, artisticProfile){
        let index = state.profiles.findIndex(profile => {
            return profile.id === artisticProfile.id;
        });

        state.profiles.splice(index, 1, artisticProfile);
    },
    [SET_ARTISTIC_PROFILES_COUNT](state, artisticProfilesCount){
        state.profilesCount = artisticProfilesCount;
    },
    [DECREMENT_ARTISTIC_PROFILE_LIMIT](state, payload){

        let limit = state.profiles.find(profile => profile.id === payload.profileId)
            .limits.find(limit => limit.name === payload.limitName);

        if (limit){
            limit.pivot.value_left--;
        }
    },
    [SET_FAVORITE_ARTISTIC_PROFILES](state, profiles){
        state.favoriteArtisticProfiles = profiles;
    },
    [REMOVE_PROFILE_FROM_FAVORITE_COLLECTION](state, artisticProfileId){
        state.favoriteArtisticProfiles = state.favoriteArtisticProfiles.filter(p => p.id !== artisticProfileId);
    }
};
