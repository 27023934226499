const GET_ARTISTIC_APPLICATIONS = 'getArtisticApplications';
const GET_ORGANIZER_ARTISTIC_APPLICATIONS = 'getOrganizerArtisticApplications';
const ARCHIVE_ARTISTIC_APPLICATION = 'archiveArtisticApplication';
const CREATE_ARTISTIC_APPLICATION = 'createArtisticApplication';
const REJECT_ARTISTIC_APPLICATION = 'rejectArtisticApplication';
const ACCEPT_ARTISTIC_APPLICATION = 'acceptArtisticApplication';
const SET_APPLICATION_CONVERSATION = 'setOrganizerApplicationConversation';

export {
    GET_ARTISTIC_APPLICATIONS,
    GET_ORGANIZER_ARTISTIC_APPLICATIONS,
    ARCHIVE_ARTISTIC_APPLICATION,
    CREATE_ARTISTIC_APPLICATION,
    REJECT_ARTISTIC_APPLICATION,
    ACCEPT_ARTISTIC_APPLICATION,
    SET_APPLICATION_CONVERSATION,
};