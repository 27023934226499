import axios from "axios";
import store from '../store';
import router from '../router/routes';

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL + process.env.VUE_APP_API_URL_SUFIX;
axios.defaults.withCredentials = true;

/*
* Handle response statuses
* */
axios.interceptors.response.use(response => {
    return response;
}, error => {

    if ( [401, 419].includes(error.response.status) ) {
        if(router.currentRoute.name){
         return location.reload();
        }
    }

    if (404 === error.response.status){
        return router.push('/404');
    }

    return Promise.reject(error);
});

/*
* send locale
* */
axios.interceptors.request.use(function (config) {
    // Apply application locale
    Object.assign(config, {
        params: {
            locale: store.state.base.locale,
            ...config.params
        },
    });

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default axios;