export default {
    isAuthenticated(state){
        return null !== state.user;
    },
    isVerified(state, getters){
        return getters.isAuthenticated ? null !== state.user.email_verified_at : false;
    },
    isOrganizer(state){
        return state.user.is_organizer;
    }
};